import SigninPage from '../../pages/Signin';
import ForgotPassword from '../../pages/ForgotPassword';
import InsightsPage from '../../pages/Insights';
import ErrorPage from '../../pages/Error';
import Overview from '../overview';
import ManagementPage from '../../pages/Management';
import UserProfilePage from '../../pages/UserProfile';
import CreativeVideoGallery from '../creative_analytics/creative_video_gallery';
import NotificationCenter from '../notification_center';
import CreativeVideoDetails from '../creative_analytics/creative_video_details';
import MediaPlanDetails from '../media_plan/media_plan_details';
import Launcher from '../launcher';
import MediaPlanGallery from '../media_plan/media_plan_gallery';
import { Cookies, Privacy, Terms } from '../gdpr';
import URLS from './urls';
import { ROLES } from '../services/vrrRoles';
import { IRoute } from './types';

export const routesMap: IRoute[] = [
  {
    url: URLS.SIGNIN,
    component: SigninPage
  },
  {
    url: URLS.TERMS,
    component: Terms
  },
  {
    url: URLS.COOKIES,
    component: Cookies
  },
  {
    url: URLS.PRIVACY,
    component: Privacy
  },
  {
    url: URLS.FORGOT_PASSWORD,
    component: ForgotPassword
  },
  {
    url: URLS.INSIGHTS,
    component: InsightsPage,
    requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER],
    navBarElements: ['account', 'date', 'funnel'],
    isPrivate: true
  },
  {
    url: URLS.NOT_FOUND,
    component: ErrorPage,
    isPrivate: true
  },
  {
    url: URLS.OVERVIEW_ALL,
    component: Overview,
    requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER],
    navBarElements: ['account', 'date', 'funnel', 'lastUpdate'],
    isPrivate: true
  },
  {
    url: URLS.OVERVIEW_YOUTUBE,
    component: Overview,
    requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER],
    navBarElements: ['account', 'date', 'funnel', 'lastUpdate'],
    isPrivate: true
  },
  {
    url: URLS.MANAGEMENT_ALL,
    component: ManagementPage,
    requires: [ROLES.MEDIA_BUYER],
    navBarElements: ['account', 'date'],
    isPrivate: true
  },
  {
    url: URLS.MANAGEMENT_YOUTUBE,
    component: ManagementPage,
    requires: [ROLES.MEDIA_BUYER],
    navBarElements: ['account', 'date'],
    isPrivate: true
  },
  {
    url: URLS.USER_PROFILE,
    component: UserProfilePage,
    navBarElements: ['account'],
    isPrivate: true
  },
  {
    url: URLS.CREATIVE_ANALYTICS,
    component: CreativeVideoGallery,
    requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER],
    navBarElements: ['account'],
    isPrivate: true
  },
  {
    url: URLS.LAUNCHER,
    component: Launcher,
    requires: [ROLES.MEDIA_BUYER],
    navBarElements: ['account'],
    isFeatureFlag: true,
    isPrivate: true
  },
  {
    url: URLS.VIDEO,
    component: CreativeVideoDetails,
    requires: [ROLES.MEDIA_BUYER],
    navBarElements: ['account'],
    isFeatureFlag: false,
    isPrivate: true
  },
  {
    url: URLS.MEDIA_PLAN_GALLERY,
    component: MediaPlanGallery,
    requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER],
    isFeatureFlag: true,
    navBarElements: ['account'],
    isPrivate: true
  },
  {
    url: URLS.MEDIA_PLAN,
    component: MediaPlanDetails,
    requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER],
    isFeatureFlag: true,
    navBarElements: ['account'],
    isPrivate: true
  },
  {
    url: URLS.NOTIFICATION_CENTER,
    component: NotificationCenter,
    requires: [ROLES.MEDIA_BUYER],
    navBarElements: [],
    isPrivate: true
  }
];
