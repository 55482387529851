import { createAsyncThunk } from '@reduxjs/toolkit';
import apiVRR from 'libs/axios/apis';
import END_POINTS from '../../../services/endpoints';
import { RootState } from '../../store';
import { TCreativeVideoDetailsAPI, TCreativeVideoDetailsOldAPI } from './types';

export const fetchVideoDetailsOld = createAsyncThunk(
  'creativeAnalytics/fetchVideoDetailsOld',
  async (param: { accountId: string; videoId: number }) => {
    const endPointURL =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_VIDEO_DETAILS.VIDEO_DETAILS_OLD(
        param.accountId,
        param.videoId.toString()
      );

    const response = await apiVRR().get(endPointURL);

    return response.data.data;
  }
);

export const fetchVideoDetails = createAsyncThunk(
  'creativeVideo/fetchVideoDetails',
  async (param: { accountId: string; videoId: number }) => {
    const endPointURL =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_VIDEO_DETAILS.VIDEO_DETAILS(
        param.accountId,
        param.videoId.toString()
      );

    const response = await apiVRR().get(endPointURL);

    return response.data.data;
  }
);

export const getVideoDetailsOld = (
  state: RootState
): TCreativeVideoDetailsOldAPI | undefined =>
  state.creativeVideoDetails.videoDetailsOld ?? undefined;

export const getVideoDetails = (
  state: RootState
): TCreativeVideoDetailsAPI | undefined =>
  state.creativeVideoDetails.videoDetails ?? undefined;
