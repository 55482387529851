import React, { useEffect, useState } from 'react';
import { CheckLg } from 'react-bootstrap-icons';
import { TPercentMetric, TStatus } from './types';
import { METRIC_STATUS } from './services';
import { getInfo } from '../../services';
import { VrrProgress } from '../../../../shared_components';
import { TVrrProgress } from '../../../../shared_components/vrr_progress/types';
import PercentMetricStyle from './PercentMetric.module.scss';
import './PercentMetric.scss';

const PercentMetric: React.FC<TPercentMetric> = ({
  metricPercent,
  metricText
}) => {
  const [metric, setMetric] = useState<TStatus | undefined>(undefined);

  useEffect(() => {
    setMetric(getInfo(Math.ceil(metricPercent ?? 0), METRIC_STATUS));
  }, [metricPercent]);

  return (
    <div className="percent-metric-content">
      <div
        className={`percent-metric-icon ${
          metricPercent === null ? 'grey' : metric?.color
        }`}
      >
        <CheckLg />
      </div>
      <div className="metric-section">
        <div className="metric-content">
          <div className="metric-percent">
            {metricPercent === null || metricPercent === undefined
              ? '-'
              : Math.ceil(metricPercent)}{' '}
            %
          </div>
          <div className="section-right">
            <div className="metric-text">{metricText}</div>
            <div>{metricPercent === null ? '-' : metric?.status}</div>
          </div>
        </div>
        <VrrProgress
          vrrBarColor={metric?.color as keyof TVrrProgress['vrrBarColor']}
          value={metricPercent}
          cssModule={PercentMetricStyle}
        />
      </div>
    </div>
  );
};

export default PercentMetric;
