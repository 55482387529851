import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../section_title';
import { ReactComponent as TvIcon } from '../../../../assets/vrr/images/tv.svg';
import { ReactComponent as DesktopIcon } from '../../../../assets/vrr/images/desktop.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/vrr/images/mobile.svg';
import { TInformationSection } from './types';
import { capitalize } from '../../../services/capitalize';
import './InformationSection.scss';

const InformationSection: React.FC<TInformationSection> = ({
  brand,
  video
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle
        title={t('creative_video_details.section_title.information_section')}
        iconClassName="tim-icons icon-atom"
      />
      <div className="information-section">
        <div className="information-section-brand">
          <p className="information-brand-name">{brand?.name}</p>
          <div className="information-brand-info">
            <span className="information-brand-vertical">
              {brand?.vertical ? capitalize(brand.vertical) : ''}
            </span>
            <span>{brand?.principal_website}</span>
          </div>
        </div>
        <div className="information-section-video">
          <p className="information-video-name">{video?.name}</p>
          <div className="information-video-info">
            <div className="information-video-column">
              <p>
                {t('creative_video_details.information_section.title.platform')}
              </p>
              <span className="information-video-platform">
                {video?.platform ? capitalize(video.platform) : ''}
              </span>
            </div>
            <div className="information-video-column">
              <p>
                {t(
                  'creative_video_details.information_section.title.objective'
                )}
              </p>
              <span
                className={`information-video-objective ${video?.objective}`}
              >
                {video?.objective ? capitalize(video.objective) : ''}
              </span>
            </div>
            <div className="information-video-column">
              <p>
                {t('creative_video_details.information_section.title.device')}
              </p>
              <div className="information-video-device">
                <MobileIcon
                  title={t(
                    'creative_video_details.information_section.devices.mobile'
                  )}
                  className={video?.devices?.mobile ? 'is-active' : ''}
                />
                <DesktopIcon
                  title={t(
                    'creative_video_details.information_section.devices.desktop'
                  )}
                  className={video?.devices?.desktop ? 'is-active' : ''}
                />
                <TvIcon
                  title={t(
                    'creative_video_details.information_section.devices.tv'
                  )}
                  className={video?.devices?.connected_tv ? 'is-active' : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationSection;
