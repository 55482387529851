import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../section_title';
import StatusMetric from './status_metric';
import PercentMetric from './percent_metric';
import { TQualityMetricSection } from './types';
import './QualityMetricSection.scss';

const QualityMetricSection: React.FC<TQualityMetricSection> = ({
  visibility,
  hook,
  brand,
  click,
  sales
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle
        title={t('creative_video_details.section_title.quality_metric_section')}
        iconClassName="tim-icons icon-atom"
      />
      <div className="quality-metrics">
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={visibility}
            metricText={t('creative_video_details.quality_metric.visibility')}
          />
        </div>
        <div className="quality-metric-column">
          <PercentMetric
            metricPercent={hook}
            metricText={t('creative_video_details.quality_metric.hook')}
          />
        </div>
        <div className="quality-metric-column">
          <PercentMetric
            metricPercent={brand}
            metricText={t('creative_video_details.quality_metric.brand')}
          />
        </div>
        <div className="quality-metric-column">
          <PercentMetric
            metricPercent={click}
            metricText={t('creative_video_details.quality_metric.click')}
          />
        </div>
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={sales}
            metricText={t('creative_video_details.quality_metric.sales')}
          />
        </div>
      </div>
    </>
  );
};

export default QualityMetricSection;
