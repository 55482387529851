import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TActionLine, TPixel } from './types';
import './ActionLine.scss';
import { VrrButton, VrrDropdown } from 'micro_front_end/shared_components';

const ActionLine: React.FC<TActionLine> = ({
  pixels,
  currentIndex,
  currentValue,
  label,
  onChangeAction,
  onRemoveAction
}: TActionLine) => {
  const [dropdownValue, setDropdownValue] = useState<string>(
    currentValue ?? ''
  );
  const [dropdownList, setDropdownList] = useState<TActionLine['pixels']>(
    pixels || []
  );

  useEffect(() => {
    if (currentValue) {
      setDropdownValue(currentValue);
    }
  }, [currentValue]);

  useEffect(() => {
    setDropdownList(pixels);
  }, [pixels]);

  const { t } = useTranslation();
  const actionText =
    label == 'final'
      ? t('media_plan_edit.media_plan_config.customer_journey.actions.final')
      : t(
          'media_plan_edit.media_plan_config.customer_journey.actions.regular',
          { rank: currentIndex }
        );

  const handleChange = (e: TPixel) => {
    onChangeAction(e);
    setDropdownValue(e.label);
  };

  const setDiamondColor = () => {
    return label === 'final' ? 'shape-color-final' : 'shape-color-action';
  };

  return (
    <>
      <div className="action-line">
        <div
          className={`pixel-summary-section action-line-shape ${setDiamondColor()}`}
        >
          <span className="action-line-squared-design">{currentIndex}</span>
          <span className="action-text">{actionText}</span>
        </div>
        <div className="pixel-action-section">
          <VrrDropdown
            list={dropdownList}
            currentValue={dropdownValue}
            placeholder={dropdownValue}
            handleChangeAction={handleChange}
          />
          {label == 'action' ? (
            <VrrButton
              className="btn-link"
              icon={{
                iconLabel: 'tim-icons icon-simple-remove'
              }}
              onClick={onRemoveAction}
            />
          ) : (
            <VrrButton
              className="btn-link hidden"
              icon={{
                iconLabel: 'tim-icons icon-simple-remove'
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ActionLine;
