import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody
} from 'reactstrap';
import { ThreeDots } from 'react-bootstrap-icons';
import { VrrModal } from '../../../../../shared_components';
import { TOtherAction } from './types';

const OtherActions: React.FC<TOtherAction> = ({
  enableArchive,
  enableLaunch,
  onArchiveAction,
  onLaunchAction
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);

  const onArchive = () => {
    setOpenArchive(false);
    if (onArchiveAction) onArchiveAction();
  };

  return (
    <>
      <Dropdown
        className="other-actions"
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
        direction="down"
      >
        <DropdownToggle>
          <ThreeDots />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => setOpenArchive(true)}
            disabled={!enableArchive}
          >
            {t('creative_gallery.creative_filter.actions.archive.label')}
          </DropdownItem>
          <DropdownItem onClick={onLaunchAction} disabled={!enableLaunch}>
            {t('creative_gallery.creative_filter.actions.launch')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <VrrModal
        className="archive-modal"
        centered
        isOpen={openArchive}
        toggleValidation={onArchive}
        toggleModal={() => setOpenArchive(false)}
        modalBody={
          <ModalBody>
            {t('creative_gallery.creative_filter.actions.archive.confirm')}
          </ModalBody>
        }
      />
    </>
  );
};

export default OtherActions;
