import React, { useEffect } from 'react';
import initPlayer from '../../../libs/player/dist';
import { TVrrVideoPlayer } from './types';
import '../../../libs/player/dist/style.css';

const VrrVideoPlayer: React.FC<TVrrVideoPlayer> = ({
  id,
  width,
  height,
  source,
  chapters,
  saliencyData,
  onStarClickAction
}) => {
  const onStarClick = (salienceId: number, isCheck: boolean) => {
    if (onStarClickAction) onStarClickAction(salienceId, isCheck);
  };

  useEffect(() => {
    initPlayer({
      id,
      starCallback: onStarClick,
      sources: source,
      chapters: chapters ?? [],
      saliencyData: saliencyData ?? {}
    });
  }, []);

  return (
    <video
      key={id}
      id={id}
      data-cy-id="video-player"
      className="video-js"
      width={width}
      height={height}
    />
  );
};

export default VrrVideoPlayer;
