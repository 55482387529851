import { TRangePeriod } from 'containers/Charts/types';
import { TDateRange } from '../../micro_front_end/nav_bar/date_time_picker/types';
import i18next from 'i18next';
import moment from 'moment';

export const VALUE_RANGE_PERIOD = {
  DAILY: { label: i18next.t('components.charts.days'), value: 'daily' },
  WEEKLY: { label: i18next.t('components.charts.weeks'), value: 'weekly' },
  MONTHLY: { label: i18next.t('components.charts.months'), value: 'monthly' }
};

export const defaultValue = (period: TDateRange): TRangePeriod => {
  // Count the diff and not the total number of days
  const numberOfDays = moment(period.to).diff(period.from, 'days') + 1;

  if (numberOfDays >= 197) {
    return VALUE_RANGE_PERIOD.MONTHLY;
  } else if (numberOfDays <= 49) {
    return VALUE_RANGE_PERIOD.DAILY;
  }

  return VALUE_RANGE_PERIOD.WEEKLY;
};

export const dropdownValues = (period: TDateRange): TRangePeriod[] => {
  // Count the diff and not the total number of days
  const numberOfDays = moment(period.to).diff(period.from, 'days') + 1;

  const returnValues: TRangePeriod[] = [];

  if (numberOfDays <= 84) {
    returnValues.push(VALUE_RANGE_PERIOD.DAILY);
  }
  if (numberOfDays >= 7 && numberOfDays <= 588) {
    returnValues.push(VALUE_RANGE_PERIOD.WEEKLY);
  }
  if (numberOfDays >= 32) {
    returnValues.push(VALUE_RANGE_PERIOD.MONTHLY);
  }

  return returnValues;
};

export const OverviewService = {
  VALUE_RANGE_PERIOD,
  defaultValue,
  dropdownValues
};

export default OverviewService;
