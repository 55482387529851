import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { TImportPreview } from './types';
import {
  VrrButton,
  VrrCollapse,
  VrrInput
} from '../../../../shared_components';
import { DEFAULT_LOCATION } from '../../creative_modal/services';
import './ImportPreview.scss';

const ImportPreview: React.FC<TImportPreview> = ({
  videoIndex,
  url,
  title,
  thumbnailUrl,
  onRemoveLine
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <>
      <div className="video-preview-title">
        <span>
          {t('creative_gallery.creative_import.import_video.title.url')}
        </span>
        <hr />
      </div>
      <div className="video-preview-url">
        <VrrInput isDisabled value={url} />
        <VrrButton
          className="btn-link video-preview-remove-button"
          icon={{
            iconLabel: 'tim-icons icon-simple-remove'
          }}
          onClick={() => onRemoveLine(videoIndex)}
        />
        <VrrCollapse
          sideIcon="left"
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="video-preview-title">
          <span>
            {t(
              'creative_gallery.creative_import.import_video.title.videoInformation'
            )}
          </span>
          <hr />
        </div>
        <VrrInput isDisabled value={title} />
        <div className="video-preview-title">
          <span>
            {t('creative_gallery.creative_import.import_video.title.thumbnail')}
          </span>
          <hr />
        </div>
        <img src={thumbnailUrl} alt={thumbnailUrl} />
        <div className="video-preview-title">
          <span>
            {t('creative_gallery.creative_import.import_video.title.location')}
          </span>
          <hr />
        </div>
        <VrrInput isDisabled value={DEFAULT_LOCATION} />
      </Collapse>
    </>
  );
};

export default ImportPreview;
