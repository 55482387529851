import { ReactComponent as TvIcon } from '../../../../../../assets/vrr/images/tv.svg';
import { ReactComponent as DesktopIcon } from '../../../../../../assets/vrr/images/desktop.svg';
import { ReactComponent as MobileIcon } from '../../../../../../assets/vrr/images/mobile.svg';
import { TPreview } from '../../../../../../redux/creativeAnalytics/creativeImportVideo/types';

export const ROOT_LOCATION = 'Origin\\';

export const PLATFORM = [
  { label: 'Youtube', value: 'youtube' },
  { label: 'Meta', value: 'meta', disabled: true },
  { label: 'Tik Tok', value: 'tiktok', disabled: true },
  { label: 'Other', value: 'other', disabled: true }
];

export const OBJECTIVE = [
  { label: 'Branding', id: 'branding' },
  { label: 'Performance', id: 'performance' }
];

export const LANGUAGE = [
  { label: 'French', value: 'fr' },
  { label: 'English', value: 'en' }
];

export const DEVICE = [
  { label: <MobileIcon />, id: 'mobile', buttonProps: { title: 'Mobile' } },
  { label: <DesktopIcon />, id: 'desktop', buttonProps: { title: 'Desktop' } },
  {
    label: <TvIcon />,
    id: 'connected_tv',
    buttonProps: { title: 'Connected TV' }
  }
];

export const prepareDevice = (
  devices?: TPreview['details']['devices']
): string => {
  if (devices) {
    const newDevice = Object.keys(devices).find(
      (key) => !!(devices as any)[key as any]
    );

    return newDevice?.toString() ?? DEVICE[0].id;
  }
  return DEVICE[0].id;
};

export const prepareUpdateDevice = (
  id: string
): TPreview['details']['devices'] => {
  const newDevice: any = {};
  DEVICE.forEach((d) => {
    newDevice[d.id] = id === d.id;
  });

  return newDevice;
};
