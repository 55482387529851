import React, { useState } from 'react';
import OptimizationBody from './optimization_body';
import OptimizationHeader from './optimization_header';
import { VrrModal } from '../../../../../../shared_components';
import { TOptimizationModal } from './types';
import { TRank } from './optimization_body/types';
import './OptimizationModal.scss';

const OptimizationModal: React.FC<TOptimizationModal> = ({
  isOpen,
  onCancelAction
}: TOptimizationModal) => {
  const [newRank, setNewRank] = useState(false);
  const [lines, setLines] = useState<TRank[] | undefined>(undefined);

  const onCancel = () => {
    setNewRank(false);
    setLines(undefined);
    if (onCancelAction) onCancelAction();
  };

  const onSubmitAction = () => {};

  const addNewLine = () => {
    setNewRank(true);
  };

  const removeLine = (newLines: TRank[]) => {
    setLines(newLines);
    setNewRank(false);
  };

  const changeLine = (newLines: TRank[]) => {
    setLines(newLines);
  };

  return (
    <VrrModal
      className="optimization-modal"
      isOpen={isOpen}
      modalHeader={
        <OptimizationHeader canAdd={!newRank} onClickAction={addNewLine} />
      }
      modalBody={
        <OptimizationBody
          line1={lines?.find((line) => line.rank === 1)}
          line2={lines?.find((line) => line.rank === 2)}
          addNewRank={newRank}
          onRemoveAction={removeLine}
          onChangeAction={changeLine}
        />
      }
      toggleModal={onCancel}
      toggleValidation={onSubmitAction}
    />
  );
};

export default OptimizationModal;
