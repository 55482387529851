import React from 'react';
import { useTranslation } from 'react-i18next';
import GlobalMetrics from './global_metrics';
import ChartsContainer from '../../containers/Charts';
import MetricPins from './metrics/metric_pins';
import ShareContainer from '../../containers/Share';
import OptimizeContainer from '../../containers/Optimize';
import BoostModalContainer from '../../containers/Budget/Boost/BoostModalContainer';
import { PrivateComponent } from '../permissions';
import { ROLES } from '../services/vrrRoles';
import './Overview.scss';
import RightBar from './right_bar';
import Metrics from './metrics/metrics/metrics';

const Overview: React.FC = () => {
  const { t } = useTranslation();
  return (
    <main className="overview-main-section">
      <section
        className="global-metrics-part"
        data-cy-id="overview-global-chart"
      >
        <h4>{t('overview.page.titles.global_metrics').toUpperCase()}</h4>
        <GlobalMetrics />
        <ChartsContainer />
      </section>
      <section className="global-insights-part">
        <h4>{t('overview.page.titles.global_insights').toUpperCase()}</h4>
        <MetricPins />
        <Metrics />
      </section>
      <aside className="right-bar-position">
        <RightBar>
          <PrivateComponent requires={[ROLES.MEDIA_BUYER]}>
            <BoostModalContainer />
            <ShareContainer />
            <OptimizeContainer />
          </PrivateComponent>
        </RightBar>
      </aside>
    </main>
  );
};

export default Overview;
