import URLS from '../routing/urls';
import { isCurrentRoute, isParentCurrentRoute } from '../routing/services';
import { IRoute } from '../routing/types';
import { TMenu } from './types';
import { ROLES } from '../services/vrrRoles';

export const overviewMenu: IRoute[] = [
  {
    url: URLS.OVERVIEW_ALL,
    name: 'All',
    isActive: isCurrentRoute(URLS.OVERVIEW_ALL)
  },
  {
    url: URLS.OVERVIEW_YOUTUBE,
    name: 'Youtube',
    isActive: isCurrentRoute(URLS.OVERVIEW_YOUTUBE)
  }
];

export const managementMenu: IRoute[] = [
  {
    url: URLS.MANAGEMENT_ALL,
    name: 'All',
    isActive: isCurrentRoute(URLS.MANAGEMENT_ALL)
  },
  {
    url: URLS.MANAGEMENT_YOUTUBE,
    name: 'Youtube',
    isActive: isCurrentRoute(URLS.MANAGEMENT_YOUTUBE)
  }
];

export const getMenu: () => TMenu[] = () => {
  const menu = [
    {
      mainRoute: {
        url: URLS.USER_PROFILE,
        name: 'User profile',
        icon: 'tim-icons icon-settings-gear-63',
        isActive: isCurrentRoute(URLS.USER_PROFILE)
      },
      id: 'userProfile'
    },
    {
      mainRoute: {
        name: 'Overview',
        icon: 'tim-icons icon-chart-pie-36',
        isActive: isParentCurrentRoute(overviewMenu)
      },
      subRoutes: overviewMenu,
      id: 'overview',
      requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER]
    },
    {
      mainRoute: {
        url: URLS.INSIGHTS,
        name: 'Insights Video',
        icon: 'tim-icons icon-video-66',
        isActive: isCurrentRoute(URLS.INSIGHTS)
      },
      id: 'insights',
      requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER]
    },
    {
      mainRoute: {
        url: URLS.CREATIVE_ANALYTICS,
        name: 'Creative Gallery',
        icon: 'tim-icons icon-molecule-40',
        isActive:
          isCurrentRoute(URLS.CREATIVE_ANALYTICS) ||
          isCurrentRoute(URLS.LAUNCHER)
      },
      id: 'creative',
      requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER]
    },
    {
      mainRoute: {
        name: 'Management',
        icon: 'tim-icons icon-tv-2'
      },
      subRoutes: managementMenu,
      id: 'management',
      requires: [ROLES.MEDIA_BUYER]
    }
  ];

  const featureFlag = [
    {
      mainRoute: {
        url: URLS.MEDIA_PLAN_GALLERY,
        name: 'Media Plan',
        icon: 'icon-media-plan',
        isActive: isCurrentRoute(URLS.MEDIA_PLAN_GALLERY)
      },
      id: 'media_plan_gallery',
      requires: [ROLES.MEDIA_BUYER, ROLES.VIEWER]
    }
  ];

  const isFeatureFlag = JSON.parse(
    localStorage.getItem('vrr-adtech') as any
  )?.FF;

  if (isFeatureFlag) menu.push(...featureFlag);
  return menu;
};
