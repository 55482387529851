import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pipeline from './pipeline';
import LauncherBody from './launcher_body';
import { VrrBreadCrumb } from '../shared_components';
import { BREADCRUMB, goGallery } from './services';
import { getLaunch, launch } from '../../redux/launcher/launcherActions';
import { resetMediaPlan } from '../../redux/mediaPlanGallery/mediaPlanGallerySlice';

const Launcher: React.FC = () => {
  const dispatch: any = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const launchDetails = useSelector(getLaunch);
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (launchDetails === undefined) {
      goGallery(history, setCurrentStep);
    }
  }, [currentStep, launchDetails]);

  useEffect(() => {
    resetMediaPlan();
  }, [location]);

  const onChange = (newStep?: number) => {
    setCurrentStep(newStep);
  };

  const onLaunch = () => {
    dispatch(launch());
    goGallery(history, setCurrentStep);
  };

  return (
    <>
      <VrrBreadCrumb
        items={BREADCRUMB}
        onClickItem={() => goGallery(history, setCurrentStep)}
      />
      <Pipeline currentStep={currentStep} onClickAction={onLaunch} />
      <LauncherBody
        launch={launchDetails}
        currentStep={currentStep}
        onChangeAction={onChange}
      />
    </>
  );
};

export default Launcher;
