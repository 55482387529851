import { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import AbstractApi from './abstractApi';

class ApiVRR extends AbstractApi {
  constructor() {
    super(
      process.env.REACT_APP_SERVER_URL
        ? `${process.env.REACT_APP_SERVER_URL}/api/v1/ui`
        : 'http://localhost:3000/api/v1/ui',
      uuidv4()
    );
  }
}

const apiVRR = (): AxiosInstance => new ApiVRR().createHttpClient();

export const returnError = (error: {
  response?: { data?: any; message: any; status: any; config?: any };
}): Promise<Error> => {
  if (!error.response) {
    return Promise.reject(
      new Error(
        JSON.stringify({
          message: error,
          status: 500
        })
      )
    );
  }
  return Promise.reject(
    new Error(
      JSON.stringify({
        data: error.response.data || undefined,
        message: error.response.message,
        status: error.response.status,
        url: error.response.config?.url
      })
    )
  );
};

export default apiVRR;
