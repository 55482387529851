import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InputUrl from './input_url';
import { getAccountId } from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import { getVideoErrors } from '../../../../../redux/creativeAnalytics/creativeImportVideo/creativeImportVideoActions';
import { TUrlForm } from './types';
import { prepareRemoveUrls, prepareUrls } from './services';
import { TUrlError } from '../../../../../redux/creativeAnalytics/creativeImportVideo/types';

const UrlForm: React.FC<TUrlForm> = ({ urls, onChangeAction }) => {
  const { t } = useTranslation();
  const currentAccountId = useSelector(getAccountId);
  const errors = useSelector(getVideoErrors) as TUrlError[];

  const onChange = (id: string, e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const links = prepareUrls(id, value, urls);
    if (onChangeAction) onChangeAction(links);
  };

  const getErrors = (url: string) => {
    let err: string | undefined;
    errors?.forEach((error) => {
      if (error.url === url) {
        err = t(`creative_gallery.import_video.url_form.${error.reason}`);
      }
    });
    return err;
  };

  const onRemove = (id: string) => {
    if (!!urls?.length) {
      const links = prepareRemoveUrls(id, urls);
      if (onChangeAction) onChangeAction(links);
    }
  };

  return (
    <Form className="url-form">
      <span>{t('creative_gallery.import_video.url_form.label.account')}</span>
      <p>{currentAccountId}</p>
      <FormGroup className="url-form-group">
        <Label>
          {t('creative_gallery.import_video.url_form.label.videoLink')}
        </Label>
        {urls?.map((url: any, index: number) => {
          return (
            <InputUrl
              data-cy-id={`import-video-form-url-${index.toString()}`}
              key={url.id}
              url={url.linkValue}
              placeholder={t(
                'creative_gallery.import_video.url_form.urlPlaceholder'
              )}
              {...(getErrors(url.linkValue) && {
                errorLink: getErrors(url.linkValue)
              })}
              canRemoveLine={urls?.length > 1}
              onRemoveLine={() => onRemove(url.id)}
              onChangeInputUrl={(e) => onChange(url.id, e)}
            />
          );
        })}
      </FormGroup>
    </Form>
  );
};
export default UrlForm;
