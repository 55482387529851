import { TMediaPlanDetails } from '../types';
import { TConfigValues } from './media_plan_config/types';
import { TAssets } from './media_plan_assets/types';
import {
  TGendersSection,
  TTargetsSection,
  TTargetsValues,
  TZonesSection
} from './media_plan_targets/types';
import allZones from '../../../../libs/geo_targets/geoTargets.min.json';
import { FUNNELS } from '../../media_plan_gallery/media_plan_card/types';

const formatGenders = (
  genders: TMediaPlanDetails['genders']
): TGendersSection => {
  return { genders };
};

const formatZones = (zones: TMediaPlanDetails['zones']): TZonesSection => {
  return {
    zones: zones?.map(
      (zone) =>
        allZones.find((item: any) => zone.criteria_id === item.criteria_id)
          ?.name ?? ''
    )
  };
};

const formatTargets = (
  targets: TMediaPlanDetails['targets'],
  targetCustomIntent: TMediaPlanDetails['target_custom_intent'],
  targetOther: TMediaPlanDetails['target_other']
): TTargetsSection => {
  return {
    targets: {
      top:
        targets?.filter((target) => target.funnel === FUNNELS.top).length ?? 0,
      middle:
        targets?.filter((target) => target.funnel === FUNNELS.middle).length ??
        0,
      bottom: (targetCustomIntent ?? 0) + (targetOther ?? 0)
    }
  };
};

export const prepareTargets = (
  mediaPlan: TMediaPlanDetails
): TTargetsValues => {
  return Object.assign(
    {},
    { ages: mediaPlan.ages },
    formatZones(mediaPlan.zones),
    formatTargets(
      mediaPlan.targets,
      mediaPlan.target_custom_intent,
      mediaPlan.target_other
    ),
    formatGenders(mediaPlan.genders)
  );
};

export const prepareMediaPlanConfig = (
  mediaPlan: TMediaPlanDetails
): TConfigValues => {
  const {
    name,
    offer,
    goal,
    optimization_target,
    optimization_type,
    pixels
  }: TMediaPlanDetails = mediaPlan;
  return {
    name,
    offer,
    goal,
    optimizationTarget: optimization_target,
    optimizationType: optimization_type,
    pixels
  };
};

export const prepareMediaPlanAssets = (
  mediaPlan: TMediaPlanDetails
): TAssets => {
  const { call_to_actions, redirections } = mediaPlan;
  return {
    call_to_actions: call_to_actions,
    redirections: redirections
  };
};
