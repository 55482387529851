import product1 from '../../../../../assets/vrr/images/media-plan-assets/product1.jpg';
import product2 from '../../../../../assets/vrr/images/media-plan-assets/product2.jpg';
import product3 from '../../../../../assets/vrr/images/media-plan-assets/product3.jpg';
import product4 from '../../../../../assets/vrr/images/media-plan-assets/product4.jpg';
import product5 from '../../../../../assets/vrr/images/media-plan-assets/product5.jpg';
import { TMediaPlanDetails } from '../../types';

export const PRODUCT_IMAGES = [
  product1,
  product2,
  product3,
  product4,
  product5
];

export const getMaxAction = (pixels: TMediaPlanDetails['pixels']): string => {
  if (pixels) {
    const newPixels = [...pixels];
    const sortPixels = newPixels?.sort((a, b) => {
      return b.rank - a.rank;
    });
    const name = sortPixels[0]?.name;
    return name ?? '-';
  }
  return '-';
};
