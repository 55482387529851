import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VrrButton } from '../../../../../shared_components';
import URLS from '../../../../../routing/urls';
import { getAccountId } from '../../../../../../redux/userPreferences/actions/userPreferencesActions';
import { ReactComponent as TvIcon } from '../../../../../../assets/vrr/images/tv.svg';
import { ReactComponent as DesktopIcon } from '../../../../../../assets/vrr/images/desktop.svg';
import { ReactComponent as MobileIcon } from '../../../../../../assets/vrr/images/mobile.svg';
import { TVideoInformation } from './types';
import './VideoInformation.scss';

const VideoInformation: React.FC<TVideoInformation> = ({
  vrr_id,
  status,
  name,
  location,
  platform,
  objective,
  devices
}) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const accountId = useSelector(getAccountId);

  const goToDetail = () => {
    if (accountId?.length && vrr_id) {
      history({
        pathname: URLS.VIDEO.replace(
          ':accountId/:videoId',
          `${accountId}/${vrr_id}`
        )
      });
    }
  };

  return (
    <>
      <div className="video-information">
        <div className="video-information-section">
          <div className="video-information-section-left">
            <div className="video-information-status">
              <div className="status-icon" />
              {t(
                'launcher.video_selection.video_line.video_information.status',
                {
                  video_status: status
                }
              ).toUpperCase()}
            </div>
            <div className="video-information-image" />
          </div>
          <div className="video-information-section-right">
            <div className="video-information-data">
              <p>
                {t(
                  'launcher.video_selection.video_line.video_information.name'
                )}
              </p>
              <div>{name}</div>
            </div>
            <div className="video-information-data">
              <p>
                {t(
                  'launcher.video_selection.video_line.video_information.location'
                )}
              </p>
              <div>{location}</div>
            </div>
          </div>
        </div>

        <div className="video-information-section">
          <div className="video-information-data">
            <p>
              {t(
                'launcher.video_selection.video_line.video_information.platform'
              )}
            </p>
            <div className="video-information-platform">{platform}</div>
          </div>
          <div className="video-information-data">
            <p>
              {t(
                'launcher.video_selection.video_line.video_information.objective'
              )}
            </p>
            <div className={`video-information-objective ${objective}`}>
              {objective}
            </div>
          </div>
          <div className="video-information-data">
            <p>
              {t(
                'launcher.video_selection.video_line.video_information.device'
              )}
            </p>
            <div className="video-information-device">
              <MobileIcon
                title={t(
                  'creative_video_details.information_section.devices.mobile'
                )}
                className={devices?.mobile ? 'is-active' : ''}
              />
              <DesktopIcon
                title={t(
                  'creative_video_details.information_section.devices.desktop'
                )}
                className={devices?.desktop ? 'is-active' : ''}
              />
              <TvIcon
                title={t(
                  'creative_video_details.information_section.devices.tv'
                )}
                className={devices?.connected_tv ? 'is-active' : ''}
              />
            </div>
          </div>
        </div>
      </div>
      <VrrButton
        icon={{ iconLabel: 'tim-icons icon-pencil' }}
        className="video-information-edit"
        onClick={goToDetail}
      >
        {t('launcher.video_selection.video_line.video_information.edit')}
      </VrrButton>
    </>
  );
};

export default VideoInformation;
