import React from 'react';
import { Trans } from 'react-i18next';
import { VrrLink } from '../shared_components';
import URLS from '../routing/urls';
import './Footer.scss';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <Trans
        i18nKey="footer.links"
        components={{
          link1: <VrrLink title="terms" link={URLS.TERMS} />,
          link2: <VrrLink title="privacy" link={URLS.PRIVACY} />,
          link3: <VrrLink title="cookies" link={URLS.COOKIES} />
        }}
      />
    </div>
  );
};

export default Footer;
