import React, { useEffect, useState } from 'react';
import { TStatus, TStatusMetric } from './types';
import { getInfo } from '../../services';
import { METRIC_STATUS } from './services';
import './StatusMetric.scss';

const StatusMetric: React.FC<TStatusMetric> = ({
  metricPercent,
  metricText
}) => {
  const [metric, setMetric] = useState<TStatus | undefined>(undefined);

  useEffect(() => {
    setMetric(getInfo(Math.ceil(metricPercent ?? 0), METRIC_STATUS));
  }, [metricPercent]);

  return (
    <div className="status-metric-content">
      <div
        className={`metric-status ${
          metricPercent === null ? 'grey' : metric?.color
        }`}
      >
        {metricPercent === null ? '-' : metric?.status}
      </div>
      <div className="metric-text">{metricText}</div>
    </div>
  );
};

export default StatusMetric;
