import { RootState } from '../store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiVRR from '../../libs/axios/apis';
import endPoints from '../../services/endpoints';
import { notify } from '../app/appSlice';
import { TEditMPDRequest, TMediaPlan, TMediaPlanPixel } from './types';
import {
  TCustomTargets,
  TMediaPlanDetails
} from '../../micro_front_end/media_plan/media_plan_details/types';
import { TUrlError } from '../creativeAnalytics/creativeImportVideo/types';
import { TObjectives } from '../../micro_front_end/media_plan/media_plan_details/media_plan_body/edit/media_plan_config/objective_modal/types';

export const fetchMediaPlans = createAsyncThunk(
  'mediaPlanGallery/fetchMediaPlans',
  async (_, { getState }) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const endpointURL =
      endPoints.MEDIA_PLAN_GALLERY.MEDIA_PLAN(currentAccountId);
    const response = await apiVRR().get(endpointURL);

    return response.data.data;
  }
);

export const fetchMediaPlan = createAsyncThunk(
  'mediaPlanGallery/fetchMediaPlan',
  async (param: { mediaPlanId: number | string }, { getState }) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const endPointURL = endPoints.MEDIA_PLAN_GALLERY.MEDIA_PLAN_DETAILS(
      currentAccountId,
      typeof param.mediaPlanId === 'number'
        ? param.mediaPlanId.toString()
        : param.mediaPlanId
    );

    const response = await apiVRR().get(endPointURL);

    return response.data.data;
  }
);

export const editMediaPlanDetails = createAsyncThunk(
  'mediaPlanGallery/editMediaPlanDetails',
  async (params: TEditMPDRequest, { dispatch, getState }) => {
    const mediaPlanId = (getState() as any).mediaPlanGallery.mediaPlan.id;
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const endpointURL = endPoints.MEDIA_PLAN_GALLERY.MEDIA_PLAN_DETAILS(
      currentAccountId,
      mediaPlanId
    );
    const response = await apiVRR().patch(endpointURL, params);

    if (response.status >= 200) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);

export const fetchPixels = createAsyncThunk(
  'mediaPlanGallery/fetchPixels',
  async (_, { getState }) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const endPointURL =
      endPoints.MEDIA_PLAN_GALLERY.MEDIA_PLAN_PIXELS(currentAccountId);

    const response = await apiVRR().get(endPointURL);

    return response.data.data;
  }
);

export const addNewMediaPlan = createAsyncThunk(
  'mediaPlanGallery/addNewMediaPlan',
  async (_, { getState }) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const endPointURL =
      endPoints.MEDIA_PLAN_GALLERY.NEW_MEDIA_PLAN(currentAccountId);

    const response = await apiVRR().post(endPointURL);

    return response.data.data;
  }
);

export const getMediaPlans = (
  state: RootState
): TMediaPlan[] | undefined | [] => state.mediaPlanGallery.mediaPlans;

export const getMediaPlan = (state: RootState): TMediaPlanDetails | undefined =>
  state.mediaPlanGallery.mediaPlan;

export const getMediaPlanGenders = (
  state: RootState
): TMediaPlanDetails['genders'] | [] =>
  state.mediaPlanGallery.mediaPlan?.genders ?? [];

export const getMediaPlanAges = (
  state: RootState
): TMediaPlanDetails['ages'] | [] =>
  state.mediaPlanGallery.mediaPlan?.ages ?? [];

export const getMediaPlanObjectives = (state: RootState): TObjectives => {
  return {
    name: state.mediaPlanGallery.mediaPlan?.name ?? '',
    goal:
      (state.mediaPlanGallery.mediaPlan?.goal as TObjectives['goal']) ||
      'impressions'
  };
};

export const getMediaPlanPixels = (
  state: RootState
): TMediaPlanDetails['pixels'] =>
  state.mediaPlanGallery.mediaPlan?.pixels ?? [];

export const getMediaPlanRedirections = (
  state: RootState
): TMediaPlanDetails['redirections'] | undefined =>
  state.mediaPlanGallery.mediaPlan?.redirections ?? undefined;

export const getMediaPlanCallToActions = (
  state: RootState
): TMediaPlanDetails['call_to_actions'] | undefined =>
  state.mediaPlanGallery.mediaPlan?.call_to_actions ?? undefined;

export const getMediaPlanZones = (
  state: RootState
): TMediaPlanDetails['zones'] | undefined =>
  state.mediaPlanGallery.mediaPlan?.zones ?? undefined;

export const getMediaPlanTargets = (
  state: RootState
): TMediaPlanDetails['targets'] | undefined =>
  state.mediaPlanGallery.mediaPlan?.targets ?? undefined;

export const getMediaPlanCustomTargets = (
  state: RootState
): TCustomTargets | undefined => {
  if (state.mediaPlanGallery.mediaPlan) {
    const {
      target_custom_intent,
      target_other,
      target_your_data_yt_users,
      target_your_data_app_users,
      target_your_data_clients
    } = state.mediaPlanGallery.mediaPlan as TMediaPlanDetails;

    return {
      target_custom_intent,
      target_other,
      target_your_data_yt_users,
      target_your_data_app_users,
      target_your_data_clients
    };
  }
};

export const getPixels = (state: RootState): TMediaPlanPixel[] | undefined =>
  state.mediaPlanGallery.pixels ?? undefined;

export const getMediaPlanErrors = (state: RootState): TUrlError[] | undefined =>
  state.mediaPlanGallery.errors;

export const getIsComplete = (state: RootState): boolean =>
  state.mediaPlanGallery.isComplete;
