import { connect } from 'react-redux';
import { getCurrentSession, signin } from '../../redux/app/appActions';
import Signin from '../../components/SignIn';

const mapStateToProps = (state: any) => ({
  session: getCurrentSession(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  launchSignin: (email: string, password: string, rememberMe: string) =>
    dispatch(signin({ email, password, rememberMe }))
});

const SigninContainer = connect(mapStateToProps, mapDispatchToProps)(Signin);

export default SigninContainer;
