import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SIDES,
  TARGETS,
  TMediaPlanTargetsSection,
  TOnClickEditButton,
  TTargets
} from './types';
import GendersSection from './GendersSection';
import DefaultSection from './DefaultSection';
import './styles/MediaPlanTargetsSection.scss';
import TargetsSection from './TargetsSection';
import EditButton from '../edit/edit_button';

const MediaPlanTargetsSection: React.FC<
  TMediaPlanTargetsSection & TOnClickEditButton
> = ({
  titleId,
  descriptionSide = SIDES.right as keyof TMediaPlanTargetsSection['descriptionSide'],
  values,
  onClickEditButton
}: TMediaPlanTargetsSection & TOnClickEditButton) => {
  const { t } = useTranslation();
  const { genders, targets } = TARGETS;
  const [section, setSection] = useState(values);

  useEffect(() => {
    setSection(values);
  }, [values]);

  const displaySection = () => {
    switch (titleId) {
      case targets:
        return <TargetsSection targets={section as TTargets} />;
      case genders:
        return <GendersSection genders={section as string[]} />;
      default:
        return <DefaultSection data={section as string[]} />;
    }
  };

  return (
    <div className="media-plan-targets-section-container">
      <div className="media-plan-targets-section-header">
        <h4 className="title-with-icon">
          <i className="tim-icons icon-spaceship circle-icon" />
          <span className="title-text">
            {t(
              `media_plan_details.media_plan_targets.media_plan_targets_section.title.${titleId}`
            )}
          </span>
        </h4>
        <EditButton
          onClickEditButton={onClickEditButton}
          titleId={titleId}
          label={t(`media_plan_details.edit_button`)}
          iconLabel="tim-icons icon-pencil"
        />
      </div>
      <div
        className={`media-plan-targets-section-body description-${descriptionSide}`}
      >
        <div className="media-plan-targets-section-body-description">
          {t(
            `media_plan_details.media_plan_targets.media_plan_targets_section.description.${titleId}`
          )}
        </div>
        <div className="media-plan-targets-section-body-values">
          {displaySection()}
        </div>
      </div>
    </div>
  );
};

export default MediaPlanTargetsSection;
