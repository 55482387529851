import React, { useEffect, useState } from 'react';
import TargetList from '../target_list';
import { VrrDropdown } from '../../../../../../shared_components';
import {
  initializeData,
  LIST,
  prepareAddTag,
  prepareRemoveTag
} from './services';
import { capitalize } from '../../../../../../services/capitalize';
import { TDefaultTargetBody } from './types';
import { TVrrTag } from '../../../../../../shared_components/vrr_tag/types';
import { IListState } from '../../../../../../shared_components/vrr_dropdown/types';

const DefaultTargetBody: React.FC<TDefaultTargetBody> = ({
  target,
  mediaPlanData,
  onChangeAction
}) => {
  const [tags, setTags] = useState<TVrrTag[]>([]);
  const [currentValues, setCurrentValues] = useState<string[]>([]);

  const addTag = (ids: IListState[], withCallback?: boolean) => {
    const { newCurrentValues, newTags } = prepareAddTag(
      currentValues,
      tags,
      ids
    );
    setCurrentValues(newCurrentValues);
    setTags(newTags);
    if (withCallback && onChangeAction) onChangeAction(newCurrentValues);
  };

  useEffect(() => {
    if (mediaPlanData?.length && target?.length) {
      const data = initializeData(mediaPlanData, LIST[target]);
      addTag(data);
    }
  }, [mediaPlanData, target]);

  const removeTag = (id?: string) => {
    const { newCurrentValues, newTags } = prepareRemoveTag(
      currentValues,
      tags,
      id
    );
    setCurrentValues(newCurrentValues);
    setTags(newTags);
    if (onChangeAction) onChangeAction(newCurrentValues);
  };

  return (
    <div className="default-target-body-modal">
      <VrrDropdown
        list={LIST[target]}
        currentValue={currentValues}
        handleChangeAction={(id: IListState[]) => addTag(id, true)}
        isMulti
        isClearable={false}
        isSearchable={false}
      />
      <TargetList
        title={capitalize(target)}
        onClickAction={(id) => removeTag(id)}
        onRemoveAllAction={removeTag}
        tags={tags}
      />
    </div>
  );
};

export default DefaultTargetBody;
