import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from 'reactstrap';
import CustomerJourneyBody from './customer_journey_body';
import { VrrButton, VrrModal } from '../../../../../../shared_components';
import { checkValidation, sortPixelsList } from './services';
import {
  editMediaPlanDetails,
  fetchPixels,
  getMediaPlanPixels,
  getPixels
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TCustomerJourneyModal } from './types';
import { TMediaPlanDetails } from '../../../../types';
import './CustomerJourneyModal.scss';

const CustomerJourneyModal: React.FC<TCustomerJourneyModal> = ({
  isOpen,
  onCancelAction
}: TCustomerJourneyModal) => {
  const adAccountPixelsList: TMediaPlanDetails['pixels'] =
    useSelector(getMediaPlanPixels);
  const dispatch = useDispatch() as any;
  const { t } = useTranslation();
  const pixels = useSelector(getPixels);
  const [currentValues, setCurrentValues] = useState<
    TMediaPlanDetails['pixels']
  >([]);

  useEffect(() => {
    if (isOpen) {
      const currentPixels = !!Object.values(adAccountPixelsList)?.length
        ? [...adAccountPixelsList]
        : [{ id: -1, rank: 1 }];
      setCurrentValues(currentPixels);
      dispatch(fetchPixels());
    }
  }, [isOpen]);

  const onCancel = () => {
    if (onCancelAction) onCancelAction();
  };

  const onSubmitAction = () => {
    const newCurrentValues =
      currentValues.length > 1 ||
      (currentValues.length === 1 && currentValues[0].id !== -1)
        ? currentValues
        : [];
    dispatch(editMediaPlanDetails({ pixels: { ...newCurrentValues } }));
    if (onCancelAction) onCancelAction();
  };

  const onChangeAction = (list: TMediaPlanDetails['pixels']) => {
    setCurrentValues(list);
  };

  const addLineAction = () => {
    const newArr = sortPixelsList([...currentValues]);
    const finalAction = newArr.pop();
    if (finalAction) {
      const { id, rank } = finalAction;
      newArr.push({ id: -1, rank: rank });
      newArr.push({ id: id, rank: rank + 1 });
    }
    setCurrentValues(newArr);
  };

  return (
    <VrrModal
      className="cj-modal"
      isOpen={isOpen}
      icon={<i className="tim-icons icon-spaceship circle-icon" />}
      title="Edit Customer Journey"
      modalHeader={
        <ModalHeader className="vrr-modal-header">
          <span>
            <i className="tim-icons icon-spaceship circle-icon" />
          </span>
          <span>
            {t(
              'media_plan_edit.media_plan_config.customer_journey.modal_header.title'
            )}
          </span>
          <VrrButton
            disabled={pixels?.length === currentValues.length}
            className="btn-round cj-modal-header-add"
            onClick={
              !(pixels?.length === currentValues.length)
                ? addLineAction
                : undefined
            }
            icon={{
              iconLabel: 'tim-icons icon-simple-add'
            }}
          >
            {t(
              'media_plan_edit.media_plan_config.customer_journey.modal_header.add_button'
            )}
          </VrrButton>
        </ModalHeader>
      }
      modalBody={
        <div className="cj-body" data-cy="cj-body">
          <CustomerJourneyBody
            selectedPixelsList={sortPixelsList(currentValues)}
            availablePixelsList={pixels ?? []}
            onChangePixel={onChangeAction}
          />
        </div>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmitAction}
      enabledValidationButton={checkValidation(currentValues)}
    />
  );
};

export default CustomerJourneyModal;
