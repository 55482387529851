import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VrrButton,
  VrrDropdown,
  VrrFloatInput
} from '../../../../../../../shared_components';
import { getGoalList, KPI_COST, KPI_RATE, prepareKpiList } from './services';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';
import { TOptimizationBody, TRank } from './types';
import './OptimizationBody.scss';

const OptimizationBody: React.FC<TOptimizationBody> = ({
  line1,
  line2,
  addNewRank = false,
  onRemoveAction,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const [rank1, setRank1] = useState<TRank>({ rank: 1 });
  const [rank2, setRank2] = useState<TRank>({ rank: 2 });

  useEffect(() => {
    if (line1) {
      setRank1(line1);
    }

    if (line2) {
      setRank2(line2);
    }
  }, [line1, line2]);

  const onChange = (type: string, selection: string, rank: number) => {
    if (rank === 1) {
      const newRank = {
        ...rank1,
        [type as keyof TRank]: selection
      };
      setRank1(newRank);
      if (onChangeAction) onChangeAction([newRank, rank2]);
    }

    if (rank === 2) {
      const newRank = {
        ...rank2,
        [type as keyof TRank]: selection
      };
      setRank2(newRank);
      if (onChangeAction) onChangeAction([rank1, newRank]);
    }
  };

  const onRemove = () => {
    setRank2({ rank: 2 });
    if (onRemoveAction) onRemoveAction([rank1]);
  };

  return (
    <div className="optimization-body-modal">
      <p>{t('media_plan_edit.media_plan_config.optimization.sentence')}</p>
      <div className="optimization-body">
        <div className="optimization-rank">
          <span>
            {t('media_plan_edit.media_plan_config.optimization.wanted')}
          </span>
          <VrrDropdown
            list={prepareKpiList(rank2.kpi)}
            handleChangeAction={(value: IListState) =>
              onChange('kpi', value.label, 1)
            }
          />
          <div className="optimization-dropdown">
            <VrrDropdown
              list={getGoalList()}
              handleChangeAction={(value: IListState) =>
                onChange('goal', value.value, 1)
              }
            />
          </div>
          {(rank1?.goal === 'at most' || rank1?.goal === 'at least') && (
            <div className="optimization-input">
              <VrrFloatInput
                decimalNumber={2}
                withCurrency={KPI_COST.includes(rank1?.kpi as string)}
                withPercent={KPI_RATE.includes(rank1?.kpi as string)}
              />
            </div>
          )}
        </div>
        {addNewRank && (
          <div className="optimization-new-rank">
            <span>
              {t('media_plan_edit.media_plan_config.optimization.maintain')}
            </span>
            <VrrDropdown
              list={prepareKpiList(rank1.kpi)}
              handleChangeAction={(value: IListState) =>
                onChange('kpi', value.label, 2)
              }
            />
            <div className="optimization-dropdown">
              <VrrDropdown
                list={getGoalList()}
                handleChangeAction={(value: IListState) =>
                  onChange('goal', value.value, 2)
                }
              />
            </div>
            {(rank2?.goal === 'at most' || rank2?.goal === 'at least') && (
              <div className="optimization-input">
                <VrrFloatInput
                  decimalNumber={2}
                  withCurrency={KPI_COST.includes(rank2?.kpi as string)}
                  withPercent={KPI_RATE.includes(rank2?.kpi as string)}
                />
              </div>
            )}
            <VrrButton
              className="optimization-remove"
              icon={{ iconLabel: 'tim-icons icon-simple-remove' }}
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OptimizationBody;
