import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { isMoment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import LauncherFooter from '../launcher_footer';
import Inputs from './inputs';
import Dates from './dates';
import BudgetChart from './budget_chart';
import {
  prepareBudget,
  prepareBudgetDates,
  prepareBudgetValues
} from './services';
import SectionTitle from '../../../creative_analytics/creative_video_details/section_title';
import { editLauncher } from '../../../../redux/launcher/launcherActions';
import { getMediaPlan } from '../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TBudget } from './types';
import { TDates } from './dates/types';
import { TBudgets } from './inputs/types';
import './Budget.scss';

const Budget: React.FC<TBudget> = ({
  isReadOnly = false,
  budgetValues,
  budgetDates,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const mediaPlan = useSelector(getMediaPlan);

  const [budget, setBudget] = useState<TBudgets | undefined>(undefined);
  const [total, setTotal] = useState(0);
  const [dates, setDates] = useState<TDates>({
    startDate: undefined,
    endDate: undefined
  });
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!isReadOnly && mediaPlan) {
      setBudget(prepareBudget(mediaPlan));
    }
  }, [mediaPlan]);

  useEffect(() => {
    if (budgetValues) {
      setBudget(prepareBudgetValues(budgetValues));
    }
  }, [budgetValues]);

  useEffect(() => {
    if (budgetDates) {
      setDates(prepareBudgetDates(budgetDates));
    }
  }, [budgetDates]);

  const isValidStep = (totalBudget?: number, newDates?: TDates) => {
    const validDate =
      isMoment(newDates?.startDate) &&
      isMoment(newDates?.endDate) &&
      moment(newDates?.startDate).isBefore(newDates?.endDate);
    setIsValid(!!totalBudget && totalBudget > 0 && validDate);
  };

  const onNext = () => {
    if (onChangeAction) onChangeAction(3);
    if (isValid && budget) {
      const { top, middle, bottom, unspecified } = budget;
      dispatch(
        editLauncher({
          top,
          middle,
          bottom,
          unspecified,
          startDate: dates.startDate,
          endDate: dates.endDate
        })
      );
    }
  };

  const onCancel = () => {
    if (onChangeAction) onChangeAction(1);
  };

  const onChangeInputs = (newBudgets: TBudgets, newTotal: number) => {
    setBudget(newBudgets);
    setTotal(newTotal);
    isValidStep(newTotal, dates);
  };

  const onChangeDates = (values: TDates) => {
    setDates(values);
    isValidStep(total, values);
  };

  return (
    <div className="budget-container">
      <div className="budget-body">
        <SectionTitle
          title={t('launcher.budget.title')}
          iconClassName="tim-icons icon-atom"
        />
        <div className="budget-form">
          <BudgetChart values={budget} />
          <Inputs
            isReadOnly={isReadOnly}
            values={budget}
            onChangeAction={onChangeInputs}
          />
        </div>
        <Dates
          values={dates}
          onChangeAction={onChangeDates}
          isReadOnly={isReadOnly}
        />
      </div>
      {!isReadOnly && (
        <LauncherFooter
          currentStep={2}
          onCancelAction={onCancel}
          onSubmitAction={onNext}
          submitDisabled={!isValid}
        />
      )}
    </div>
  );
};

export default Budget;
