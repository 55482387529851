import { TMediaPlanDetails } from './types';

export const prepareColorStep = (mediaPlan?: TMediaPlanDetails) => {
  switch (mediaPlan?.goal) {
    case 'impressions':
    case 'views':
      return mediaPlan?.completed_steps >= 7 ? 'cyan' : 'grey';
    case 'clicks':
      return mediaPlan?.completed_steps >= 8 ? 'cyan' : 'grey';
    case 'visits':
    case 'add_to_cart':
    case 'sales':
    case 'leads':
    case 'app_install':
      return mediaPlan?.completed_steps >= 9 ? 'cyan' : 'grey';
    default:
      return 'grey';
  }
};
