import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { v4 as uuid } from 'uuid';
import { ModalBody, ModalHeader } from 'reactstrap';
import { VrrModal } from '../../../shared_components';
import ImportForm from './import_form';
import AddButton from './import_form/add_button';
import {
  TImportUrl,
  TVideo
} from '../../../../redux/creativeAnalytics/creativeImportVideo/types';
import {
  getVideoPreviews,
  importVideosAction,
  saveVideos
} from '../../../../redux/creativeAnalytics/creativeImportVideo/creativeImportVideoActions';
import ImportPreview from './import_preview';
import { getRootId } from '../../../../redux/creativeAnalytics/creativeGallery/actions/creativeGalleryActions';
import './CreativeImportModal.scss';
import { TCreativeImportModal } from './types';

const CreativeImportModal: React.FC<TCreativeImportModal> = ({
  isOpen,
  onCancelAction
}) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const [urls, setUrls] = useState<TImportUrl[]>([
    { linkValue: '', id: uuid() }
  ]);
  const [currentForm, setCurrentForm] = useState<'first' | 'last'>('first');
  const [previews, setPreviews] = useState<TVideo[]>([]);

  const videoPreviews = useSelector(getVideoPreviews);
  const rootId = useSelector(getRootId);

  useEffect(() => {
    if (!!videoPreviews?.length) {
      setPreviews(videoPreviews);
    }
  }, [videoPreviews]);

  useEffect(() => {
    setCurrentForm(!previews?.length ? 'first' : 'last');
  }, [previews]);

  const onCancel = () => {
    setUrls([{ linkValue: '', id: uuid() }]);
    setPreviews([]);
    setCurrentForm('first');
    if (onCancelAction) onCancelAction();
  };

  const onSubmitUrl = () => {
    const videos: string[] = [];
    urls.forEach((url) => {
      if (!!url?.linkValue?.length) {
        videos.push(url.linkValue);
      }
    });
    dispatch(importVideosAction(videos));
  };

  const onSubmit = () => {
    if (previews && rootId) {
      const videos = previews.map((video) => {
        return {
          ...video,
          folderId: rootId
        };
      });
      dispatch(saveVideos(videos));
    }
    onCancel();
  };

  const onRemoveUrl = (id: string) => {
    setUrls((values) => {
      const newUrls = [...values];
      return newUrls.filter((value) => value.id !== id);
    });
  };

  const onRemovePreview = (index: number) => {
    const filteredArray = previews.filter(
      (item) => previews.indexOf(item) !== index
    );
    setPreviews(filteredArray);
  };

  const onChangeUrl = (id: string, value: string) => {
    setUrls((links) => {
      const newUrls = [...links];
      return newUrls.map((link) => {
        if (link.id === id) {
          return {
            ...link,
            linkValue: value
          };
        }
        return link;
      });
    });
  };

  const addNewLine = () => {
    setUrls((values) => {
      const newUrls = [...values];
      newUrls.push({ linkValue: '', id: uuid() });
      return newUrls;
    });
  };

  return (
    <VrrModal
      isOpen={isOpen}
      modalHeader={
        <ModalHeader
          className="vrr-modal-header"
          {...(currentForm === 'first' && {
            close: <AddButton onClickAction={addNewLine} />
          })}
        >
          <span>
            <PlusCircleFill size="1.5rem" />
          </span>
          <span>
            {t(
              'creative_gallery.creative_import.import_video.import_modal.header'
            )}
          </span>
        </ModalHeader>
      }
      modalBody={
        <ModalBody className="import-modal-body">
          {currentForm === 'first' ? (
            <ImportForm
              urls={urls}
              onChangeAction={onChangeUrl}
              onRemoveAction={onRemoveUrl}
            />
          ) : (
            previews?.map((preview: TVideo, index: number) => {
              return (
                <ImportPreview
                  key={preview.url}
                  videoIndex={index}
                  url={preview.url}
                  title={preview.title}
                  thumbnailUrl={preview.thumbnailUrl}
                  onRemoveLine={onRemovePreview}
                />
              );
            })
          )}
        </ModalBody>
      }
      toggleModal={onCancel}
      enabledValidationButton={
        (currentForm === 'first' &&
          urls.some((url) => !!url.linkValue?.length)) ||
        currentForm === 'last'
      }
      toggleValidation={currentForm === 'first' ? onSubmitUrl : onSubmit}
      dataCyCancelButton="import-video-cancel"
    />
  );
};

export default CreativeImportModal;
