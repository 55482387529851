import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchUserProfile,
  getUserProfile,
  updateUserProfile
} from '../../../redux/userProfile/userProfileActions';
import GeneralInformations from '../../../components/UserProfile/GeneralInformations';
import { IGeneralInformationC } from './types';
import { ISaveUserProfile } from 'redux/userProfile/types';

const GeneralInformationC = ({
  currentUserProfile,
  onSaveUserProfile,
  getUserProfileInformation
}: IGeneralInformationC) => {
  useEffect(() => {
    getUserProfileInformation();
  }, []);
  return (
    <GeneralInformations
      currentUserProfile={currentUserProfile}
      onSaveUserProfile={onSaveUserProfile}
    />
  );
};

const mapStateToProps = (state: any) => ({
  currentUserProfile: getUserProfile(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserProfileInformation: () => dispatch(fetchUserProfile()),
  onSaveUserProfile: (user: ISaveUserProfile) =>
    dispatch(updateUserProfile(user))
});

const GeneralInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInformationC);

export default GeneralInformationContainer;
