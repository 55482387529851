import React from 'react';
import { connect } from 'react-redux';
import URLS from './urls';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getCurrentSession } from '../../redux/app/appActions';
import Layout from '../layout';
import PrivateRoute from './PrivateRoute';
import { IRoute, IRoutesC } from './types';
import { routesMap } from './mapping';

const VrrRoutesC: React.FC<IRoutesC> = ({ session }: IRoutesC) => {
  const generateRoutes = () =>
    routesMap.map((item: IRoute) => {
      const {
        component: Component,
        url,
        requires,
        isPrivate,
        isFeatureFlag,
        navBarElements,
        name,
        props
      }: IRoute = item;

      const componentProps: any = {
        path: url,
        url,
        requires,
        isPrivate,
        isFeatureFlag,
        navBarElements,
        session,
        ...props
      };

      return (
        <Route
          path={url}
          key={url}
          element={
            isPrivate ? (
              <PrivateRoute key={name} {...componentProps}>
                <Layout>
                  <Component
                    routerProps={{ ...componentProps }}
                    component={Component}
                  />
                </Layout>
              </PrivateRoute>
            ) : (
              <Component
                routerProps={{ ...componentProps }}
                component={Component}
              />
            )
          }
        />
      );
    });

  return (
    <Routes>
      {generateRoutes()}
      <Route path={URLS.HOME} element={<Navigate to={URLS.OVERVIEW_ALL} />} />
      <Route path="*" element={<Navigate to={URLS.NOT_FOUND} />} />
    </Routes>
  );
};

const mapStateToProps = (state: any) => ({
  session: getCurrentSession(state)
});

const VrrRoutes = connect(mapStateToProps)(VrrRoutesC);

export default VrrRoutes;
