import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import './ChartDataTypes.scss';
import { IChartTypes, IChartDataTypes } from './types';

export const SPENT = 'spent';
export const IMPRESSIONS = 'impressions';
export const VALUE = 'value';
export const COST = 'cost';
export const RATIO = 'ratio';

const CHART_TYPES: IChartTypes[] = [
  {
    id: VALUE,
    name: 'components.chart.chartTypes.value'
  },
  {
    id: COST,
    name: 'components.chart.chartTypes.cost'
  },
  {
    id: RATIO,
    name: 'components.chart.chartTypes.ratio'
  }
];

const ChartDataTypes: React.FC<IChartDataTypes> = ({
  onClickAction
}: IChartDataTypes) => {
  const [active, setActive] = useState(VALUE);
  const { t } = useTranslation();

  const handleClickAction = (buttonId: IChartTypes['id']) => {
    setActive(buttonId);
    if (onClickAction) onClickAction(buttonId);
  };

  const renderButtonList = () => {
    return CHART_TYPES?.map((button) => (
      <Button
        key={button.id}
        size="sm"
        className={`btn-simple ${button.id === active ? 'active' : ''}`}
        onClick={() => handleClickAction(button.id)}
      >
        {t(button.name).toUpperCase()}
      </Button>
    ));
  };

  return <div className="data-types-buttons">{renderButtonList()}</div>;
};

export default ChartDataTypes;
