import React from 'react';
import { ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { VrrButton } from 'micro_front_end/shared_components';
import { TCtaModalHeader } from './types';
import CtaHeaderModalStyles from './CtaModalHeader.module.scss';

export const CtaModalHeader: React.FC<TCtaModalHeader> = ({
  addLineAction
}: TCtaModalHeader) => {
  const { t } = useTranslation();
  return (
    <ModalHeader className="cta-modal-header" cssModule={CtaHeaderModalStyles}>
      <span>
        <span>
          <i className="tim-icons icon-spaceship circle-icon" />
        </span>
        <span>
          {t('media_plan_edit.media_plan_assets.call_to_actions.modal.title')}
        </span>
      </span>
      <VrrButton
        className="btn-round cta-modal-add"
        onClick={addLineAction}
        icon={{
          iconLabel: 'tim-icons icon-simple-add'
        }}
      >
        {t('media_plan_edit.media_plan_assets.redirections.modal.add')}
      </VrrButton>
    </ModalHeader>
  );
};

export default CtaModalHeader;
