import React from 'react';
import { TTableRow } from './types';
import './styles/VrrTableRow.scss';

const VrrTableRow: React.FC<TTableRow> = ({
  cellElements,
  actions,
  rowId,
  onClickLine
}) => {
  const onClick = () => {
    if (onClickLine) onClickLine(rowId, cellElements);
  };

  return (
    <tr
      key={rowId}
      className={onClickLine ? 'line-with-action' : ''}
      onClick={onClick}
    >
      {cellElements?.map((element, index) => (
        <td
          className={`vrr-table-row ${element?.rowClassName ?? ''}`}
          key={`${rowId}-${cellElements[index]?.headerId ?? 0}`}
        >
          {element?.component ?? element?.value}
        </td>
      ))}
      {actions?.length && (
        <td>
          {actions?.map((action) => {
            return React.cloneElement(action.component, {
              'data-id': action.id,
              'data-row': rowId,
              key: `action-${action.id}`
            });
          })}
        </td>
      )}
    </tr>
  );
};

export default VrrTableRow;
