import React, { useEffect, useState } from 'react';
import { Nav, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PrivateComponent } from '../permissions';
import { toLowerKebabCase } from '../services/formatters/formatString';
import { TMenuItem } from './menu_item/types';
import MenuItem from './menu_item';
import MenuLanguage from './menu_language';
import { getMenu } from './services';
import classNames from 'classnames';
import logoImage from '../../assets/vrr/images/logo.svg';
import './Menu.scss';

const Menu: React.FC = () => {
  const [bulletIsOpen, setBulletIsOpen] = useState(
    document.body.classList.contains('nav-open')
  );
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('sidebar-mini');
  }, []);

  const toggleSidebar = () => {
    document.body.classList.toggle('nav-open');
    document.body.classList.toggle('sidebar-mini');

    setBulletIsOpen(!bulletIsOpen);
  };

  return (
    <div className="sidebar-root" id="cy-sidebar-root">
      <i
        role="button"
        tabIndex={0}
        aria-label="bullet list"
        className={classNames(
          'tim-icons icon-bullet-list-67',
          'bullet-list',
          bulletIsOpen ? 'bullet-list-close' : 'bullet-list-open'
        )}
        onClick={toggleSidebar}
        onKeyDown={toggleSidebar}
      />
      <div className={classNames('sidebar', 'sidebar-ext')}>
        <div className={classNames('sidebar-wrapper', 'vrr-background')}>
          <div>
            <div className="logo">
              <NavLink className="simple-text logo-mini">
                <div className="logo-img">
                  <img src={logoImage} alt="vrr-logo" />
                </div>
              </NavLink>
              <NavLink className="simple-text logo-normal">
                {t('menu.logo').toUpperCase()}
              </NavLink>
            </div>
            {getMenu().map((item: TMenuItem, index: number) => (
              <Nav
                id={toLowerKebabCase(`cy-sidebar-link-${item.mainRoute.name}`)}
                key={item.mainRoute.name ?? index.toString()}
              >
                <PrivateComponent requires={item.requires}>
                  <MenuItem
                    id={item.id || index.toString()}
                    mainRoute={item.mainRoute}
                    subRoutes={item.subRoutes}
                  />
                </PrivateComponent>
              </Nav>
            ))}
          </div>
          <MenuLanguage />
        </div>
      </div>
    </div>
  );
};

export default Menu;
