import React, { useEffect, PropsWithChildren, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '../menu';
import Footer from '../footer';
import NavBar from '../nav_bar';
import RestrictionContainer from '../../containers/Restriction';
import {
  fetchUserProfile,
  getUserProfile
} from '../../redux/userProfile/userProfileActions';
import './Layout.scss';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch: any = useDispatch();
  const currentUserProfile = useSelector(getUserProfile);

  useEffect(() => {
    if (!currentUserProfile.username?.length) {
      dispatch(fetchUserProfile());
    }
  }, [currentUserProfile]);

  return (
    <>
      <Menu />
      <NavBar
        elements={
          (children as ReactElement)?.props?.routerProps?.navBarElements
        }
      />
      <div className="locate-children">{children}</div>
      <RestrictionContainer />
      <Footer />
    </>
  );
};

export default Layout;
