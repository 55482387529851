import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoLine from './video_line';
import LauncherFooter from '../launcher_footer';
import SectionTitle from '../../../creative_analytics/creative_video_details/section_title';
import { TVideoSelection } from './types';
import './VideoSelection.scss';

const VideoSelection: React.FC<TVideoSelection> = ({
  videos,
  onChangeAction
}) => {
  const { t } = useTranslation();

  const onNext = () => {
    if (onChangeAction) onChangeAction(1);
  };

  const onChange = () => {
    if (onChangeAction) onChangeAction(undefined);
  };

  return (
    <>
      <div className="video-selection">
        <SectionTitle
          title={t('launcher.video_selection.title')}
          iconClassName="tim-icons icon-atom"
        />
        {videos?.map((video, index) => (
          <VideoLine
            key={`video-line-${index + 1}`}
            vrr_id={video.vrr_id}
            status={video.status}
            name={video.name}
            location={video.location}
            platform={video.platform}
            objective={video.objective}
            devices={video.devices}
            score={video.score}
          />
        ))}
      </div>
      <LauncherFooter
        currentStep={0}
        onCancelAction={onChange}
        onSubmitAction={onNext}
      />
    </>
  );
};

export default VideoSelection;
