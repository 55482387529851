import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import useSettings from '../../../../libs/settings/useSettings';
import AccountModal from '../../../../micro_front_end/user_profile/connected_account/account_modal';
import AccountsTable from '../../../../micro_front_end/user_profile/connected_account/account_modal/accounts_table';
import LabelModal from '../../../../micro_front_end/user_profile/connected_account/label_modal/LabelModal';
import './AdwordsAccountList.scss';
import { IAdwordsAccountList } from './types';

const AdwordsAccountList: React.FC<IAdwordsAccountList> = ({
  list,
  addConnectedAccountAction,
  removeConnectedAccountAction
}: IAdwordsAccountList) => {
  const { t } = useTranslation();
  const { vrr } = useSettings();
  const [isOpenAddAccountModal, setOpenAddAccountModal] = React.useState(false);
  const [accountSelected, setAccountSelected] = React.useState<string[]>([]);
  const [selectedAccount, setSelectedAccount] = React.useState('');

  const onClickPlusButton = (account: string) => {
    setSelectedAccount(account);
  };

  const closeModal = () => {
    setSelectedAccount('');
    setOpenAddAccountModal(false);
  };

  return (
    <div className="adwords-account-list">
      <Row>
        <Col>
          <Button
            data-cy-id="user-profile-add-accounts-button"
            primary="true"
            onClick={() => {
              setOpenAddAccountModal(true);
            }}
          >
            {t('connected_account.account_modal.title')}
          </Button>
        </Col>
        <Col />
        <Col>
          <Button
            primary="true"
            onClick={() => {
              removeConnectedAccountAction(
                accountSelected.map((item) => parseInt(item))
              );
              setAccountSelected([]);
            }}
            disabled={accountSelected.length < 1}
          >
            {t('components.adword_account.disconnect_account')}
          </Button>
        </Col>
        <Col>
          <Button
            primary="true"
            onClick={() =>
              removeConnectedAccountAction(
                list.map((item: any) => item.id as number)
              )
            }
          >
            {t('components.adword_account.disconnect_all_account')}
          </Button>
        </Col>
      </Row>
      {Array.isArray(list) && (
        <AccountsTable
          id="accounts"
          accounts={list}
          onChangeAction={(itemListSelected) => {
            setAccountSelected(itemListSelected);
          }}
          onActionClick={onClickPlusButton}
        />
      )}
      {selectedAccount.length > 0 && (
        <LabelModal
          id="connected-account-labels"
          account={selectedAccount}
          onSubmitAction={(result) =>
            vrr({ custom_labels_list: { ...result } }, true)
          }
          isOpen={selectedAccount.length > 0}
          onCancelAction={closeModal}
        />
      )}
      <AccountModal
        id="addAccount"
        isOpen={isOpenAddAccountModal}
        onCancelAction={() => setOpenAddAccountModal(false)}
        onSubmitAction={addConnectedAccountAction}
      />
    </div>
  );
};

export default AdwordsAccountList;
