import {
  TActionLineDropdownPixel,
  TAdAccountPixel,
  TCustomerJourneyBody
} from './types';
import { TMediaPlanPixel } from '../../../../../../../../redux/mediaPlanGallery/types';
import { TPixel } from '../action_line/types';

export const formatPixel = (
  pixelId: TMediaPlanPixel['id'] | TAdAccountPixel['id'],
  referenceList: TCustomerJourneyBody['availablePixelsList']
): TActionLineDropdownPixel | undefined => {
  const correspondingPix = !!referenceList?.length
    ? referenceList?.find((x) => x.id == pixelId)
    : undefined;
  if (correspondingPix) {
    return {
      label: correspondingPix.name,
      value: correspondingPix.id.toString()
    };
  }
};

export const remainingPixels = (
  referenceList: TCustomerJourneyBody['availablePixelsList'],
  selectedList: TCustomerJourneyBody['selectedPixelsList']
): number[] => {
  const available = !!referenceList?.length
    ? referenceList?.map((pix) => pix.id)
    : [];
  const selected = !!selectedList?.length
    ? selectedList?.map((pix) => pix.id)
    : [];
  return available?.filter((val) => !selected?.includes(val));
};

export const formatAvailablePixelsForDropdown = (
  referenceList: TCustomerJourneyBody['availablePixelsList'],
  selectedList: TCustomerJourneyBody['selectedPixelsList']
): TActionLineDropdownPixel[] => {
  return remainingPixels(referenceList, selectedList).reduce(
    (result: TActionLineDropdownPixel[], pixelId: number) => {
      const formatted = formatPixel(pixelId, referenceList);
      if (formatted) {
        result.push(formatted);
      }
      return result;
    },
    []
  );
};

export const updatedRankedPixelsList = (
  newPixel: TActionLineDropdownPixel,
  selectedList: TCustomerJourneyBody['selectedPixelsList'],
  oldPixel?: TAdAccountPixel
): TCustomerJourneyBody['selectedPixelsList'] => {
  const newRankedPixels = [...selectedList];
  const index = selectedList.findIndex((x) => x.rank == oldPixel?.rank);
  const oldPixelIndex = index >= 0 ? index : 0;
  newRankedPixels[oldPixelIndex] = {
    id: Number(newPixel.value),
    rank: oldPixel?.rank ?? 0
  };

  return newRankedPixels;
};

export const prepareList = (
  list: TPixel[],
  isFinalAction: boolean
): TPixel[] => {
  let newList = [...list];
  if (isFinalAction) {
    newList = [{ label: '', value: '-1' }, ...list];
    return newList;
  }
  return list;
};
