import { getIcon } from '../../../services/services';
import { TGlobalMetric } from '../../../redux/userPreferences/types';
import { TSelectionMetric } from './deck_metrics/card_metric/types';

export const removeColor = (
  color: string,
  availableColors: string[],
  callback: (colors: string[]) => void
): void => {
  const newMetricColors = [...availableColors];
  const index = newMetricColors.indexOf(color);
  if (index > -1 && newMetricColors.length > 0) {
    newMetricColors.splice(index, 1);
  }
  callback(newMetricColors);
};

export const addColor = (
  color: string,
  availableColors: string[],
  callback: (colors: string[]) => void
): void => {
  const newMetricColors = [...availableColors];
  if (color !== 'grey') {
    newMetricColors.push(color);
  }
  callback(newMetricColors);
};

export const setSelectedMetricsInAll = (
  metrics?: TSelectionMetric[],
  metricsSelectedByAccount?: TGlobalMetric | null
): TSelectionMetric[] | undefined => {
  return Array.isArray(metrics)
    ? metrics.map((metric: TSelectionMetric) => {
        let newMetric = { ...metric };
        const metricSettings = metricsSelectedByAccount?.metrics?.find(
          (m) => m.name.value === metric.name.value
        );

        if (metricSettings) {
          newMetric = {
            ...newMetric,
            selected: true,
            ...(metric.type !== 'spent' &&
              metricSettings?.color && { color: metricSettings?.color }),
            ...(metric.type && {
              icon: getIcon(
                metric.type,
                `gm-icon ${
                  metricSettings?.color ??
                  ((metric.type === 'spent' && 'red') || 'grey')
                }`
              )
            })
          };
        }
        return newMetric;
      })
    : undefined;
};
