import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUserProfile,
  fetchConnectedAccountURL,
  fetchAvailableAccounts,
  updateUserProfile,
  updateUserProfilePassword,
  fetchConnectedAccountCallback,
  removeConnectedAccount,
  addConnectedAccount,
  fetchAccountLabels,
  refreshAccountLabels
} from './userProfileActions';
import { logout } from '../app/appSlice';
import { prepareDemoFeatureFlag } from '../../services/featureDev/featureDev';
import { IUserProfile } from './types';

export const initialState: IUserProfile = {
  email: '',
  username: '',
  restricted: false,
  organizationRole: null,
  connectedAccount: {
    url: '',
    isConnected: false,
    availableAccounts: undefined,
    accountLabels: undefined
  }
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
      prepareDemoFeatureFlag(payload.email);
      return {
        ...state,
        username: payload.username,
        email: payload.email,
        restricted: payload.restricted,
        organizationRole: payload.role
      };
    });
    builder.addCase(logout, () => {
      return {
        ...initialState
      };
    });
    builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    });
    builder.addCase(
      fetchConnectedAccountURL.fulfilled,
      (state, { payload }: any) => {
        return {
          ...state,
          connectedAccount: {
            ...state.connectedAccount,
            url: payload.data.data
          }
        };
      }
    );
    builder.addCase(fetchAccountLabels.fulfilled, (state, { payload }: any) => {
      return {
        ...state,
        connectedAccount: {
          ...state.connectedAccount,
          accountLabels: [...payload]
        }
      };
    });
    builder.addCase(refreshAccountLabels.pending, (state: any) => {
      return {
        ...state,
        connectedAccount: {
          ...state.connectedAccount,
          accountLabels: undefined
        }
      };
    });

    builder.addCase(
      refreshAccountLabels.fulfilled,
      (state, { payload }: any) => {
        return {
          ...state,
          connectedAccount: {
            ...state.connectedAccount,
            accountLabels: [...payload]
          }
        };
      }
    );
    builder.addCase(fetchAvailableAccounts.pending, (state) => {
      return {
        ...state,
        connectedAccount: {
          ...state.connectedAccount,
          availableAccounts: undefined
        }
      };
    });
    builder.addCase(
      fetchAvailableAccounts.fulfilled,
      (state, { payload }: any) => {
        return {
          ...state,
          connectedAccount: {
            ...state.connectedAccount,
            availableAccounts: payload.data.data.list.map((list: any) => {
              const {
                account_name,
                ad_account_relation,
                currency_code,
                ...rest
              } = list;
              return {
                ...rest,
                label: account_name,
                adAccountRelation: ad_account_relation,
                currencyCode: currency_code
              };
            })
          }
        };
      }
    );
    builder.addCase(addConnectedAccount.fulfilled, (state, { payload }) => {
      return {
        ...state,
        connectedAccount: {
          ...state.connectedAccount,
          availableAccounts: payload.data.data.account_available.map(
            (list: any) => {
              const {
                account_name,
                ad_account_relation,
                currency_code,
                ...rest
              } = list;
              return {
                ...rest,
                label: account_name,
                adAccountRelation: ad_account_relation,
                currencyCode: currency_code
              };
            }
          )
        }
      };
    });
    builder.addCase(removeConnectedAccount.fulfilled, (state, { payload }) => {
      return {
        ...state,
        connectedAccount: {
          ...state.connectedAccount,
          availableAccounts: payload.data.data.account_available.map(
            (list: any) => {
              const {
                account_name,
                ad_account_relation,
                currency_code,
                ...rest
              } = list;
              return {
                ...rest,
                label: account_name,
                adAccountRelation: ad_account_relation,
                currencyCode: currency_code
              };
            }
          )
        }
      };
    });
    builder.addCase(fetchConnectedAccountCallback.fulfilled, (state) => {
      return {
        ...state,
        connectedAccount: {
          ...state.connectedAccount,
          isConnected: true
        }
      };
    });
    builder.addCase(
      updateUserProfilePassword.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          ...payload,
          restricted: false
        };
      }
    );
  }
});

export default userProfileSlice.reducer;
