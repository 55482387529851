import React, { useEffect, useState } from 'react';
import VrrTable from '../../../../shared_components/vrr_table';
import { prepareHeader, prepareBody } from './services';
import { TAccountsTable } from './types';
import { VrrButton } from '../../../../shared_components';
import './AccountsTable.scss';

const AccountsTable: React.FC<TAccountsTable> = ({
  cyId,
  id,
  accounts,
  onChangeAction,
  onActionClick
}: TAccountsTable) => {
  const [dataTable, setDataTable] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      setDataTable([]);
    };
  }, []);

  useEffect(() => {
    if (accounts) {
      document
        .querySelectorAll('input[type=checkbox]')
        .forEach((el: any) => (el.checked = false));

      setDataTable([]);
    }
  }, [accounts]);

  const onChange = (value: string, isChecked: boolean) => {
    const newData = [...dataTable];
    if (isChecked) {
      newData.push(value);
    } else {
      newData.splice(newData.indexOf(value), 1);
    }
    setDataTable(newData);
    if (onChangeAction) onChangeAction(newData);
  };

  return (
    <VrrTable
      cyId={cyId}
      header={prepareHeader()}
      body={prepareBody(id, accounts, onChange)}
      tableProps={{
        borderless: true,
        striped: true,
        className: 'accounts-table'
      }}
      {...(!!onActionClick && {
        actions: [
          {
            id: 'addLabel',
            component: (
              <VrrButton
                className="btn-link add-label"
                data-cy-id="user-profile-add-label-button"
                icon={{
                  iconLabel: 'tim-icons icon-simple-add'
                }}
                onClick={(e) => {
                  if (accounts && onActionClick && e.currentTarget.dataset.row)
                    onActionClick(
                      accounts[parseInt(e.currentTarget.dataset.row)].id
                    );
                }}
              />
            )
          }
        ]
      })}
    />
  );
};

export default AccountsTable;
