import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import VrrRoutes from './micro_front_end/routing';
import NotificationContainer from './containers/Notification/NotificationContainer';
import {
  atLeastOneStrategy,
  PermissionsProvider
} from './micro_front_end/permissions';
import { prepareCurrency } from './services/services';
import { fetchUserPreferences } from './redux/userPreferences/actions/userPreferencesAPIs';
import { getCurrencyCode, getCurrentSession } from './redux/app/appActions';
import './App.scss';

export const App: React.FC = () => {
  const dispatch: any = useDispatch();
  const currentSession = useSelector(getCurrentSession);
  const currencyCode = useSelector(getCurrencyCode);

  useEffect(() => {
    if (currentSession && currentSession !== '') {
      dispatch(fetchUserPreferences());
    }
  }, [currentSession]);

  useEffect(() => {
    if (currencyCode) {
      prepareCurrency(currencyCode);
    }
  }, [currencyCode]);

  return (
    <PermissionsProvider
      permissions={[
        JSON.parse(localStorage.getItem('vrr-adtech') as any)?.role
      ]}
      authorizationStrategy={atLeastOneStrategy}
    >
      <BrowserRouter>
        <VrrRoutes />
        <NotificationContainer />
      </BrowserRouter>
    </PermissionsProvider>
  );
};

export default App;
