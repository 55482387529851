import React from 'react';
import VrrButton from 'micro_front_end/shared_components/vrr_button';
import VrrInput from '../../../../../shared_components/vrr_input';
import { TImportInputUrl } from './types';
import './ImportInputUrl.scss';

const ImportInputUrl: React.FC<TImportInputUrl> = ({
  id,
  url,
  placeholder,
  onChangeInputUrl,
  errorLink,
  canRemoveLine,
  onRemoveLine
}: TImportInputUrl) => {
  return (
    <div
      id={id}
      className="import-input-url-container"
      data-cy-id="import-video-url-form"
    >
      <VrrInput
        value={url}
        type="url"
        placeholder={placeholder}
        onChangeAction={onChangeInputUrl}
        errorMessage={errorLink}
      />
      {canRemoveLine && (
        <VrrButton
          className="btn-link"
          icon={{
            iconLabel: 'tim-icons icon-simple-remove'
          }}
          onClick={onRemoveLine}
        />
      )}
    </div>
  );
};

export default ImportInputUrl;
