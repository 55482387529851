import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import {
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { VrrModal } from '../../../shared_components';
import VrrInput from '../../../shared_components/vrr_input';
import { DEFAULT_LOCATION } from './services';
import { TCreativeModal, TCreativeVideoFolder } from './types';
import './CreativeModal.scss';

const CreativeModal: React.FC<TCreativeModal> = ({
  isOpen,
  folder,
  onCancelAction,
  onSubmitAction,
  dataCy,
  dataCyCancel
}) => {
  const { t } = useTranslation();
  const [folderData, setFolderData] = useState<
    TCreativeVideoFolder | undefined
  >(undefined);

  useEffect(() => {
    if (folder) {
      setFolderData(folder);
    }
  }, [folder]);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFolderData({
      ...folderData,
      name: e.currentTarget.value
    });
  };

  const onCancel = () => {
    setFolderData(undefined);
    if (onCancelAction) onCancelAction();
  };

  const onSubmit = () => {
    if (onSubmitAction && folderData) onSubmitAction(folderData);
    onCancel();
  };

  return (
    <VrrModal
      isOpen={isOpen}
      modalHeader={
        <ModalHeader className="creative-modal-header">
          <span>
            <i className="tim-icons icon-zoom-split circle-icon" />
          </span>
          <span className="creative-modal-title">
            {t('creative_gallery.creative_modal.title').toUpperCase()}
          </span>
        </ModalHeader>
      }
      modalBody={
        <ModalBody className="creative-modal-body">
          <Form className="creative-modal-form">
            <FormGroup className="creative-modal-form-input folder-name-input">
              <Label
                for="folder-name"
                className="folder-name-label creative-modal-label"
              >
                {t('creative_gallery.creative_modal.labels.name')}
              </Label>
              <Input
                data-cy-id={dataCy}
                autoFocus
                id="folder-name"
                name="folder-name"
                className="creative-folder-name-input"
                onChange={onChange}
                value={folderData?.name}
                onKeyUp={(e) => {
                  if (
                    e.key === 'Enter' &&
                    !!folderData?.name.length &&
                    onSubmitAction
                  ) {
                    onSubmitAction(folderData);
                    setFolderData(undefined);
                  }
                }}
              />
            </FormGroup>
            <FormGroup className="creative-modal-form-input location-input">
              <Label
                for="location"
                className="location-label creative-modal-label"
              >
                {t('creative_gallery.creative_modal.labels.location')}
              </Label>
              <VrrInput
                id="location"
                type="text"
                className="creative-location-input"
                isDisabled
                value={DEFAULT_LOCATION}
              />
            </FormGroup>
            {folder && (
              <FormGroup className="creative-modal-form-input updated-date-input">
                <Label
                  for="updated-date"
                  className="updated-date-label creative-modal-label"
                >
                  {t('creative_gallery.creative_modal.labels.date')}
                </Label>
                <VrrInput
                  type="text"
                  id="updated-date"
                  className="creative-updated-date-input"
                  isDisabled
                  value={moment(folderData?.updatedDate).format('LLLL')}
                />
              </FormGroup>
            )}
          </Form>
        </ModalBody>
      }
      toggleModal={onCancel}
      enabledValidationButton={!!folderData?.name.length}
      toggleValidation={onSubmit}
      dataCyCancelButton={dataCyCancel}
    />
  );
};

export default CreativeModal;
