import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import ReactDatetime from 'react-datetime';
import moment, { Moment } from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { TBudgetDates, TDates } from './types';
import './Dates.scss';

const Dates: React.FC<TBudgetDates> = ({
  isReadOnly = false,
  values,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState<TDates>({
    startDate: undefined,
    endDate: undefined
  });
  const format = i18next.language == 'en' ? 'MMM Do YYYY' : 'DD MMMM YYYY';

  useEffect(() => {
    if (values) {
      setDates(values);
    }
  }, [values]);

  const onChange = (type: string, value?: Moment) => {
    const newDates: TDates = { ...dates };
    newDates[type as keyof TDates] = value;
    setDates(newDates);
    if (onChangeAction) onChangeAction(newDates);
  };

  const isValidDate = (current: any) => {
    const yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  return (
    <div className="budget-dates">
      <div>
        <div className="budget-dates-label">
          <span>{t('launcher.budget.dates.start')}</span>
          <hr />
        </div>
        <ReactDatetime
          className="budget-date"
          inputprops={{
            locale: i18next.language
          }}
          timeFormat={false}
          dateFormat={format}
          moment={moment.locale(i18next.language)}
          onChange={(startDate: Moment) => {
            onChange('startDate', startDate);
          }}
          value={dates.startDate}
          isValidDate={isValidDate}
          inputProps={{ disabled: isReadOnly }}
        />
      </div>
      <div>
        <div className="budget-dates-label">
          <span>{t('launcher.budget.dates.end')}</span>
          <hr />
        </div>
        <ReactDatetime
          className="budget-date"
          inputprops={{
            locale: i18next.language
          }}
          timeFormat={false}
          dateFormat={format}
          moment={moment.locale(i18next.language)}
          onChange={(endDate: Moment) => {
            onChange('endDate', endDate);
          }}
          value={dates.endDate}
          isValidDate={isValidDate}
          inputProps={{ disabled: isReadOnly }}
        />
      </div>
    </div>
  );
};

export default Dates;
