import React from 'react';
import { VrrButton, VrrInput } from '../../../../../shared_components';
import { TInputUrl } from './types';
import './InputUrl.scss';

const InputUrl: React.FC<TInputUrl> = ({
  id,
  url,
  placeholder,
  onChangeInputUrl,
  errorLink,
  canRemoveLine,
  onRemoveLine
}) => {
  return (
    <div id={id} className="input-url-container">
      <VrrInput
        value={url}
        type="url"
        placeholder={placeholder}
        onChangeAction={onChangeInputUrl}
        errorMessage={errorLink}
      />
      {canRemoveLine && (
        <VrrButton
          className="btn-link"
          icon={{
            iconLabel: 'tim-icons icon-simple-remove'
          }}
          onClick={onRemoveLine}
        />
      )}
    </div>
  );
};

export default InputUrl;
