import i18next from 'i18next';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';

export const KPI_RATE: string[] = [
  'VTR',
  'CTR',
  'Visit Rate',
  'Conversion Rate'
];
export const KPI_COST: string[] = ['CPV', 'CPC', 'CPVisit', 'CPA'];

export const KPI_LIST: IListState[] = [
  {
    value: 'cpv',
    label: 'CPV'
  },
  {
    value: 'cpc',
    label: 'CPC'
  },
  {
    value: 'cpvisit',
    label: 'CPVisit'
  },
  {
    value: 'cpa',
    label: 'CPA'
  },
  {
    value: 'roas',
    label: 'ROAS'
  },
  {
    value: 'vtr',
    label: 'VTR'
  },
  {
    value: 'ctr',
    label: 'CTR'
  },
  {
    value: 'visit_rate',
    label: 'Visit Rate'
  },
  {
    value: 'conversion_rate',
    label: 'Conversion Rate'
  }
];

export const getGoalList = () => [
  {
    value: 'as low',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.as_low'
    )
  },
  {
    value: 'as high',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.as_high'
    )
  },
  {
    value: 'at most',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.at_most'
    )
  },
  {
    value: 'at least',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.at_least'
    )
  }
];

export const prepareKpiList = (kpi?: string) => {
  if (kpi) {
    return KPI_LIST.filter((node) => node.label !== kpi);
  }
  return KPI_LIST;
};
