import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CardList from '../../../components/Insights/CardList';
import { ROLES } from '../../../micro_front_end/services/vrrRoles';
import VrrCircleLoader from '../../../micro_front_end/shared_components/vrr_circle_loader';
import { INSIGHTS_CARD_STATUS } from '../../../services/constants/status';
import useSettings from '../../../libs/settings/useSettings';
import services from './CardListContainer.services';
import {
  getCurrentLabel,
  getRoleByCurrentAccount
} from '../../../redux/app/appActions';
import {
  getAccountId,
  getInsightsCardsByCurrentAccount,
  getInsightsFiltersByCurrentAccount,
  getPeriod
} from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  getInsights,
  fetchInsights,
  getFilters
} from '../../../redux/insights/insightsActions';
import { TCardInsightsPreference } from '../FilterContainer/types';
import { TCardsContainer } from '../CardsContainer/types';
import { TCardListContainer } from './types';
import { TDateRange } from '../../../micro_front_end/nav_bar/date_time_picker/types';
import './CardListContainer.scss';

const CardListC: React.FC<TCardListContainer> = ({
  insightsList,
  cardPreferences,
  insightsFiltersPreferences,
  getInsightsAction,
  period,
  accountID,
  filters,
  funnel,
  userRole
}: TCardListContainer) => {
  const { insights } = useSettings();
  const [currentPeriod, setCurrentPeriod] = useState<TDateRange | null>();
  const [currentAccount, setCurrentAccount] = useState<string | null>(null);
  const [list, setList] = useState<TCardsContainer[]>([]);
  const [insightsListFiltered, setInsightsListFiltered] = useState<
    TCardsContainer[]
  >([]);

  useEffect(() => {
    if (
      period?.from !== currentPeriod?.from ||
      period?.to !== currentPeriod?.to ||
      accountID !== currentAccount
    ) {
      setCurrentAccount(accountID);
      setCurrentPeriod(period);
    }
  }, [period, accountID]);

  useEffect(() => {
    if (
      currentAccount &&
      currentAccount !== '' &&
      currentPeriod?.from &&
      currentPeriod?.to
    ) {
      getInsightsAction();
    }
  }, [currentPeriod, currentAccount, funnel]);

  useEffect(() => {
    if (insightsList) {
      setList(
        services.prepareInsights(insightsList, cardPreferences?.status ?? [])
      );
    }
  }, [insightsList, cardPreferences]);

  useEffect(() => {
    setInsightsListFiltered(
      services.prepareFilterInsights(
        list,
        insightsFiltersPreferences ?? {
          status: userRole === ROLES.MEDIA_BUYER ? 'unpublished' : 'published'
        },
        cardPreferences?.status ?? []
      )
    );
  }, [list, insightsFiltersPreferences]);

  const updateCardsStatus = (cardId: string, newStatus: string) => {
    let status = cardPreferences?.status ?? [];
    status = status.filter((stat) => stat.id !== cardId);

    if (newStatus !== INSIGHTS_CARD_STATUS.UNPUBLISHED) {
      status.push({
        id: cardId,
        status: newStatus as TCardInsightsPreference['status']
      });
    }

    return status;
  };

  const changeStatus = (cardId: string, newStatus: string) => {
    insights('cards', { status: updateCardsStatus(cardId, newStatus) });
  };

  return (
    <>
      <div className="spacer"></div>
      <VrrCircleLoader isEnable={insightsList === null}>
        {filters && (
          <CardList
            list={insightsListFiltered}
            changeStatusAction={changeStatus}
            filters={filters}
          />
        )}
      </VrrCircleLoader>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getInsightsAction: () => dispatch(fetchInsights())
});

const mapStateToProps = (state: any) => ({
  filters: getFilters(state),
  insightsList: getInsights(state),
  period: getPeriod(state),
  accountID: getAccountId(state),
  insightsFiltersPreferences: getInsightsFiltersByCurrentAccount(state),
  cardPreferences: getInsightsCardsByCurrentAccount(state),
  funnel: getCurrentLabel(state),
  userRole: getRoleByCurrentAccount(state)
});

const CardListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardListC);

export default CardListContainer;
