const ENABLE_USER_EMAIL = [
  'demo@videorunrun.com',
  'supersmoketest@test.videorunrun.com'
];

export const prepareDemoFeatureFlag = (email: string | undefined): void => {
  const data = JSON.parse(localStorage.getItem('vrr-adtech') as any);
  localStorage.setItem(
    'vrr-adtech',
    JSON.stringify({
      ...data,
      FF: email && ENABLE_USER_EMAIL.includes(email)
    })
  );
};
