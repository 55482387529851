import { createAsyncThunk } from '@reduxjs/toolkit';
import apiVRR from '../../../libs/axios/apis';
import endPoints from '../../../services/endpoints';
import {
  IPreference,
  TAllMetricsPreferences,
  TGlobalMetric,
  TInsightsCards,
  TInsightsFilters
} from '../types';
import { getRoleByCurrentAccount } from '../../app/appActions';
import { ROLES } from '../../../micro_front_end/services/vrrRoles';
import { notify } from '../../app/appSlice';
import { prepareSettingsByCurrentAccount } from '../../../services/services';
import CardListContainerServices from '../../../containers/Insights/CardListContainer/CardListContainer.services';
import {
  createPreferences,
  getInsightsCardsByCurrentAccount,
  updatePreferences
} from './userPreferencesActions';

export const fetchUserPreferences = createAsyncThunk(
  'user/fetchPreferences',
  async () => {
    const response = await apiVRR().get(endPoints.USER.USER_PREFERENCES);
    return response.data.data;
  }
);

export const updateUserPreferences = createAsyncThunk(
  'user/updatePreferences',
  async (
    { node, value, notification }: IPreference,
    { getState, dispatch }
  ) => {
    const currentRole = getRoleByCurrentAccount(getState() as any);
    const settingsStates = {
      ...(getState() as any).userPreferences.settings
    };
    const isCurrentAccountUpdate = !!Object.keys(value).find(
      (key) => key === 'current_account_id'
    );
    const canUpdate =
      settingsStates.vrr.current_account_id &&
      (currentRole !== ROLES.VIEWER ||
        (currentRole == ROLES.VIEWER && isCurrentAccountUpdate));
    const result = {
      settings: {
        ...settingsStates,
        [node]: {
          ...settingsStates[node],
          ...value
        }
      }
    };
    if (canUpdate) {
      const response = await apiVRR().post(
        endPoints.USER.USER_PREFERENCES,
        result
      );
      if (response.status >= 200 && response.status < 400 && notification) {
        dispatch(notify({ type: 'success' }));
      }
    }
    return result;
  }
);

export const addViewer = createAsyncThunk(
  'user/addViewer',
  async ({ emails, updateViewersSettings }: any, { getState, dispatch }) => {
    const settingsStates = {
      ...(getState() as any).userPreferences.settings.vrr
    };
    const response = await apiVRR().post(endPoints.OVERVIEW.SHARE, {
      account_id: settingsStates.current_account_id,
      update_viewers_settings: updateViewersSettings,
      emails
    });

    if (response.status >= 200 && response.status < 400) {
      dispatch(notify({ type: 'success' }));
    }
    return response;
  }
);

export const preparePreferences = createAsyncThunk(
  'user/preparePreferences',
  async ({ mainNode, node, newSetting }: any, { getState, dispatch }) => {
    const { vrr } = (getState() as any).userPreferences.settings;
    const account = vrr.current_account_id;
    const preferences =
      (getState() as any).userPreferences.settings?.[mainNode]?.[node] || [];
    const settings = prepareSettingsByCurrentAccount(account, preferences) as
      | TGlobalMetric
      | TAllMetricsPreferences
      | TInsightsFilters;
    let nodeSettings:
      | TGlobalMetric[]
      | TAllMetricsPreferences[]
      | TInsightsFilters[]
      | []
      | null;
    if (settings) {
      nodeSettings = updatePreferences(account, newSetting, preferences);
    } else {
      nodeSettings = createPreferences(account, newSetting, preferences);
    }

    dispatch(
      updateUserPreferences({
        node: mainNode,
        value: { [node]: nodeSettings }
      })
    );
  }
);

export const unHideAllInsightsCards = createAsyncThunk(
  'user/unHideAllInsightsCards',
  async (_, { getState, dispatch }) => {
    const cardsPreferences: TInsightsCards | null =
      getInsightsCardsByCurrentAccount(getState() as any);

    if (cardsPreferences) {
      const newCardsPreferences =
        CardListContainerServices.removeAllHideCards(cardsPreferences);

      dispatch(
        preparePreferences({
          mainNode: 'insights',
          node: 'cards',
          newSetting: newCardsPreferences
        })
      );
    }
  }
);
