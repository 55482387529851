import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VrrDropdown, VrrInput } from '../../../../../shared_components';
import { VERTICALS } from './services';
import { TBrandPreview } from './types';
import { IListState } from '../../../../../shared_components/vrr_dropdown/types';
import { TCreativeImportVideo } from '../../../../../../redux/creativeAnalytics/creativeImportVideo/types';
import { getVideoErrors } from '../../../../../../redux/creativeAnalytics/creativeImportVideo/creativeImportVideoActions';

const BrandPreview: React.FC<TBrandPreview> = ({ brand, onChangeAction }) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<
    TCreativeImportVideo['brand'] | undefined
  >(undefined);
  const error = useSelector(getVideoErrors);

  useEffect(() => {
    if (brand) {
      setPreview(brand);
    }
  }, [brand]);

  const onChange = (
    key: keyof TCreativeImportVideo['brand'],
    value: string
  ) => {
    const newPreview: TCreativeImportVideo['brand'] = {
      ...preview,
      [key]: value
    };
    setPreview(newPreview);
    if (onChangeAction && preview) onChangeAction(newPreview);
  };

  return (
    <>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.brand.title.name')}
        </span>
        <hr />
      </div>
      <div className="brand-preview">
        <VrrInput
          value={preview?.name ?? ''}
          onChangeAction={(e) =>
            onChange(
              'name' as keyof TCreativeImportVideo['brand'],
              e.currentTarget.value
            )
          }
        />
      </div>
      <div className="preview-title">
        <span>
          {t(
            'creative_gallery.import_video.preview.brand.title.principal_website'
          )}
        </span>
        <hr />
      </div>
      <div className="brand-preview">
        <VrrInput
          value={preview?.principal_website ?? ''}
          onChangeAction={(e) =>
            onChange(
              'principal_website' as keyof TCreativeImportVideo['brand'],
              e.currentTarget.value
            )
          }
          {...(!!error && {
            errorMessage: t(
              `creative_gallery.import_video.preview.brand.${error}`
            )
          })}
        />
      </div>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.brand.title.vertical')}
        </span>
        <hr />
      </div>
      <div className="brand-preview">
        <VrrDropdown
          list={VERTICALS}
          currentValue={preview?.vertical}
          handleChangeAction={(e: IListState) => {
            onChange(
              'vertical' as keyof TCreativeImportVideo['brand'],
              e.value
            );
          }}
        />
      </div>
    </>
  );
};

export default BrandPreview;
