import React from 'react';
import { useTranslation } from 'react-i18next';
import EditButton from '../edit/edit_button';
import { getMaxAction, PRODUCT_IMAGES } from './services';
import { getCurrentCurrency } from '../../../../../services/services';
import { TMediaPlanConfig } from './types';
import './MediaPlanConfig.scss';

const MediaPlanConfig: React.FC<TMediaPlanConfig> = ({
  configValues,
  onClickEditButton
}: TMediaPlanConfig) => {
  const { t } = useTranslation();
  const { name, goal, optimizationTarget, optimizationType, pixels } =
    configValues;
  return (
    <div className="media-plan-config">
      <section className="config-part">
        <div className="name-part sub-config-part">
          <div className="highlighting highlight-title">
            <EditButton
              onClickEditButton={onClickEditButton}
              titleId="objective"
              iconLabel="tim-icons icon-pencil"
              label={t(`media_plan_details.edit_button`)}
            />
            <h2 className="highlighting-strong config-text">
              {name?.length ? name : '-'}
            </h2>
          </div>
        </div>
        <div className="objective-part sub-config-part">
          <h4 className="config-text title-with-icon">
            <i className="tim-icons icon-spaceship circle-icon" />
            <span className="title-text">
              {t('media_plan_details.media_plan_config.objective.title')}
            </span>
          </h4>
          <div className="highlighting highlight-detail">
            <EditButton
              onClickEditButton={onClickEditButton}
              titleId="objective"
              iconLabel="tim-icons icon-pencil"
              label={t(`media_plan_details.edit_button`)}
            />
            <p className="config-text highlighting-strong">
              {t('media_plan_details.media_plan_config.objective.highlight', {
                goal
              })}
            </p>
          </div>
        </div>
        <div className="optimization-part sub-config-part">
          <h4 className="config-text title-with-icon">
            <i className="tim-icons icon-tag circle-icon" />
            <span className="title-text">
              {t('media_plan_details.media_plan_config.optimization.title')}
            </span>
          </h4>
          <div className="highlighting-group">
            <div className="highlighting highlight-detail">
              <EditButton
                onClickEditButton={onClickEditButton}
                titleId="customerJourney"
                iconLabel="tim-icons icon-pencil"
                label={t(`media_plan_details.edit_button`)}
              />
              <p className="config-text highlighting-strong">
                {getMaxAction(pixels)}
              </p>
              <p className="config-text sub-detail">
                {t(
                  'media_plan_details.media_plan_config.optimization.highlight.pixel'
                )}
              </p>
            </div>
            <div className="highlighting highlight-detail">
              <EditButton
                onClickEditButton={onClickEditButton}
                titleId="cost"
                iconLabel="tim-icons icon-pencil"
                label={t(`media_plan_details.edit_button`)}
              />
              <p className="config-text highlighting-strong">
                {optimizationTarget
                  ? `${parseFloat(optimizationTarget).toFixed(
                      2
                    )} ${getCurrentCurrency()}`
                  : '-'}
              </p>
              <p className="config-text sub-detail">
                {t(
                  'media_plan_details.media_plan_config.optimization.highlight.optimization',
                  {
                    optimizationType: optimizationType?.length
                      ? optimizationType
                      : '{CPA}'
                  }
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
      <aside className="img-part">
        <img
          src={
            PRODUCT_IMAGES[Math.floor(Math.random() * PRODUCT_IMAGES.length)]
          }
          alt="product image"
        />
      </aside>
    </div>
  );
};

export default MediaPlanConfig;
