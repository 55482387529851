import React from 'react';
import { ModalBody } from 'reactstrap';
import UrlForm from '../url_form';
import { TImportBody } from './types';
import './ImportBody.scss';
import Preview from '../preview';
import { TVideoPreview } from '../preview/types';

const ImportBody: React.FC<TImportBody> = ({
  isUrlForm,
  urls,
  previews,
  onChangeUrl,
  onChangePreview,
  onChangeBrand
}) => {
  return (
    <ModalBody className="import-video-body">
      {isUrlForm ? (
        <UrlForm urls={urls} onChangeAction={onChangeUrl} />
      ) : (
        <Preview
          previews={previews as TVideoPreview['previews']}
          onChangePreview={onChangePreview}
          onChangeBrand={onChangeBrand}
        />
      )}
    </ModalBody>
  );
};

export default ImportBody;
