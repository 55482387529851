import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VrrTable } from '../../shared_components';
import { prepareBody, prepareHeader } from './services';
import {
  getNotifications,
  updateNotification
} from '../../../redux/app/appActions';
import { TNotification } from '../../../redux/vrrTypes';
import { sortByDate } from '../../nav_bar/notification_widget/notification_list/services';
import './NotificationTable.scss';

const NotificationTable: React.FC = () => {
  const dispatch: any = useDispatch();
  const notif = useSelector(getNotifications);
  const [notifications, setNotifications] = useState<
    TNotification[] | undefined
  >(undefined);

  useEffect(() => {
    if (!!notif?.length) {
      setNotifications(sortByDate(notif));
    }
  }, [notif]);

  const onUpdate = (rowId: string, line: any) => {
    const notificationId = line[rowId].id;
    const notification = notifications?.find(
      (n) => n.id.toString() === notificationId
    );

    if (notification && !notification?.isRead) {
      dispatch(updateNotification(notificationId));
    }
  };

  return (
    <VrrTable
      header={prepareHeader()}
      body={prepareBody(notifications)}
      withFilter
      tableProps={{
        borderless: true,
        className: 'notification-table'
      }}
      onClickLine={onUpdate}
      withPagination
    />
  );
};

export default NotificationTable;
