import React from 'react';
import useAuthorize from '../permissions/hooks/useAuthorize';
import useNotification from '../../libs/notifications/useNotification';
import { Navigate } from 'react-router-dom';
import URLS from './urls';
import { useTranslation } from 'react-i18next';
import { IPrivateRoute } from './types';

const PrivateRoute: React.FC<IPrivateRoute> = ({
  authorizationStrategy,
  requires,
  isFeatureFlag,
  session,
  children
}: IPrivateRoute) => {
  const isAuthorized = useAuthorize(requires, authorizationStrategy);
  const notification = useNotification();
  const { t } = useTranslation();

  const redirectToProfile = () => {
    notification('customType', {
      message: t('components.notification.not_authorized.message'),
      type: 'danger',
      icon: 'tim-icons icon-alert-circle-exc',
      autoDismiss: 3,
      place: 'tc'
    });
    return <Navigate to={URLS.USER_PROFILE} />;
  };

  const redirectToLogin = () => {
    return <Navigate to={URLS.SIGNIN} />;
  };

  const isAuthenticate = () => {
    return !!session;
  };

  const hasRole = () => {
    return (requires && isAuthorized) || !requires;
  };

  const withFeatureFlag = () => {
    const featureFlag = JSON.parse(
      localStorage.getItem('vrr-adtech') as any
    )?.FF;
    return isFeatureFlag && featureFlag;
  };

  const redirectToPage = () => {
    return withFeatureFlag() || (!isFeatureFlag && hasRole())
      ? children
      : redirectToProfile();
  };

  return isAuthenticate() ? redirectToPage() : redirectToLogin();
};

export default PrivateRoute;
