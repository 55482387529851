import { IRoute } from './types';

export const isParentCurrentRoute = (childrenRoutes: IRoute[]): boolean => {
  const { pathname } = window.location;
  const validUrl = childrenRoutes.filter((route) => {
    return pathname === route.url;
  });
  return validUrl.length > 0;
};

export const isCurrentRoute = (url: string): boolean => {
  const { pathname } = window.location;
  return pathname === url;
};
