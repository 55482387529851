import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TargetBodyModal from './target_body_modal';
import { prepareValues } from './services';
import { VrrModal } from '../../../../../../shared_components';
import {
  editMediaPlanDetails,
  getMediaPlanCustomTargets,
  getMediaPlanTargets
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TEditTarget, TTargetModal } from './types';
import { TCustomTargets } from '../../../../types';
import './TargetModal.scss';

export const TargetModal: React.FC<TTargetModal> = ({
  isOpen,
  onCancelAction
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const selections = useSelector(getMediaPlanTargets);
  const customSelections = useSelector(getMediaPlanCustomTargets);

  const [editTargets, setEditTargets] = useState<TEditTarget | undefined>(
    undefined
  );

  const onChangeAction = (newTargets?: TEditTarget | TCustomTargets) => {
    const newEditTargets = prepareValues(editTargets, newTargets);
    setEditTargets(newEditTargets);
  };

  const onSubmitAction = () => {
    if (editTargets) {
      dispatch(editMediaPlanDetails(editTargets));
    }
    if (onCancelAction) {
      onCancelAction();
    }
  };

  return (
    <VrrModal
      className="target-modal"
      isOpen={isOpen}
      modalBody={
        <TargetBodyModal
          selections={selections}
          customSelections={customSelections}
          onChangeAction={onChangeAction}
        />
      }
      toggleModal={onCancelAction}
      title={t('media_plan_edit.media_plan_targets.target_modal.title')}
      icon={<i className="tim-icons icon-spaceship circle-icon" />}
      toggle={onCancelAction}
      toggleValidation={onSubmitAction}
    />
  );
};

export default TargetModal;
