import React from 'react';
import { useTranslation } from 'react-i18next';
import { TAddButton } from './types';
import VrrButton from '../../../../../shared_components/vrr_button';

const AddButton: React.FC<TAddButton> = ({ onClickAction }) => {
  const { t } = useTranslation();

  return (
    <VrrButton
      className="btn-round import-modal-header-add"
      onClick={onClickAction}
      icon={{
        iconLabel: 'tim-icons icon-simple-add'
      }}
    >
      {t('creative_gallery.creative_import.add_button')}
    </VrrButton>
  );
};

export default AddButton;
