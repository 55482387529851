import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionLine from '../action_line/ActionLine';
import {
  formatAvailablePixelsForDropdown,
  formatPixel,
  prepareList,
  updatedRankedPixelsList
} from './services';
import { TPixel } from '../action_line/types';
import { TCustomerJourneyBody, TAdAccountPixel } from './types';

const CustomerJourneyBody: React.FC<TCustomerJourneyBody> = ({
  availablePixelsList,
  selectedPixelsList,
  onChangePixel
}) => {
  const { t } = useTranslation();

  const [dropdownsList, setDropdownsList] = useState<TPixel[]>([]);

  useEffect(() => {
    setDropdownsList(
      formatAvailablePixelsForDropdown(availablePixelsList, selectedPixelsList)
    );
  }, [availablePixelsList, selectedPixelsList]);

  const handleChange = (pixel: TPixel, oldPixel?: TAdAccountPixel) => {
    const updatedList = updatedRankedPixelsList(
      pixel,
      selectedPixelsList,
      oldPixel
    );
    onChangePixel(updatedList);
  };

  const handleRemove = (rank: number) => {
    const filteredList = selectedPixelsList.filter((pix) => pix.rank !== rank);
    const updatedList = filteredList.map((pix) => {
      return { id: pix.id, rank: pix.rank > rank ? pix.rank - 1 : pix.rank };
    });
    onChangePixel(updatedList);
  };

  return (
    <>
      <div className="cj-modal-header">
        {t(
          'media_plan_edit.media_plan_config.customer_journey.modal_body.text'
        )}
      </div>
      {selectedPixelsList.length ? (
        selectedPixelsList.map((pixel, index) => {
          return (
            <ActionLine
              key={`customer-action-${pixel.rank}-${pixel.id}`}
              pixels={prepareList(
                dropdownsList,
                index == selectedPixelsList.length - 1
              )}
              currentIndex={pixel.rank}
              currentValue={formatPixel(pixel.id, availablePixelsList)?.label}
              label={
                index == selectedPixelsList.length - 1 ? 'final' : 'action'
              }
              onChangeAction={(newPixel: TPixel) =>
                handleChange(newPixel, pixel)
              }
              onRemoveAction={() => handleRemove(pixel.rank)}
            />
          );
        })
      ) : (
        <ActionLine
          key="customer-action-1"
          pixels={prepareList(dropdownsList, true)}
          currentIndex={1}
          label="final"
          onChangeAction={(newPixel: TPixel) => handleChange(newPixel)}
          onRemoveAction={() => handleRemove(1)}
        />
      )}
    </>
  );
};

export default CustomerJourneyBody;
