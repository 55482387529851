import usePermissions from './usePermissions';

const useAuthorize = (
  requires: string[] | string,
  authorizationStrategy?: (permissions: any, requirement: any) => any
): boolean => {
  const { permissions, authorizationStrategy: defaultStrategy } =
    usePermissions();

  return authorizationStrategy
    ? authorizationStrategy(permissions, requires)
    : defaultStrategy(permissions, requires);
};

export default useAuthorize;
