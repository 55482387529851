export const permissionsStrategy = (
  permissions: string[],
  requirement: number
): string | boolean => {
  return permissions[requirement as any] || false;
};

export const roleBasedStrategy = (
  roles: string[],
  requirement: string
): boolean => {
  return !!roles.find((role) => role === requirement);
};

export const atLeastOneStrategy = (
  permissions: string[],
  requirement: string[] | string
): string | boolean => {
  if (Array.isArray(requirement) && permissions?.length > 0) {
    return !!requirement.find((role) => {
      return permissions.indexOf(role) !== -1;
    });
  }
  return permissions?.find((perm) => requirement === perm) ?? false;
};
