import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DefaultTargetBody from './DefaultTargetBody';
import { VrrModal } from '../../../../../../shared_components';
import {
  editMediaPlanDetails,
  getMediaPlanAges,
  getMediaPlanGenders
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TDefaultTargetModal } from './types';
import './DefaultTargetModal.scss';

const DefaultTargetModal: React.FC<TDefaultTargetModal> = ({
  isOpen,
  targetId,
  onCancelAction
}: TDefaultTargetModal) => {
  const { t } = useTranslation();
  const [currentValues, setCurrentValues] = useState<string[]>([]);
  const mediaPlanAges = useSelector(getMediaPlanAges);
  const mediaPlanGenders = useSelector(getMediaPlanGenders);
  const dispatch = useDispatch() as any;

  const currentTarget =
    targetId === 'genders' ? mediaPlanGenders : mediaPlanAges;

  useEffect(() => {
    if (
      (targetId === 'genders' && mediaPlanGenders?.length) ||
      (targetId === 'ages' && mediaPlanAges?.length)
    ) {
      setCurrentValues(currentTarget);
    }
  }, [mediaPlanGenders, mediaPlanAges, targetId]);

  const onCancel = () => {
    setCurrentValues(currentTarget);
    if (onCancelAction) onCancelAction();
  };

  const onChange = (values: string[]) => {
    setCurrentValues(values);
  };

  const onSubmitAction = () => {
    dispatch(editMediaPlanDetails({ [targetId]: [...currentValues] }));
    if (onCancelAction) onCancelAction();
  };

  const targets = {
    ages: {
      title: t(
        'media_plan_edit.media_plan_targets.default_target_modal.targets.ages.title'
      ),
      body: (
        <DefaultTargetBody
          target="ages"
          mediaPlanData={currentValues}
          onChangeAction={onChange}
        />
      )
    },
    genders: {
      title: t(
        'media_plan_edit.media_plan_targets.default_target_modal.targets.genders.title'
      ),
      body: (
        <DefaultTargetBody
          target="genders"
          mediaPlanData={currentValues}
          onChangeAction={onChange}
        />
      )
    }
  };

  return (
    <VrrModal
      className="default-target-modal"
      isOpen={isOpen}
      icon={<i className="tim-icons icon-spaceship circle-icon" />}
      title={targets[targetId]?.title}
      modalBody={targets[targetId]?.body}
      toggleModal={onCancel}
      toggleValidation={onSubmitAction}
      toggle={onCancel}
      dataCyCancelButton={`cancel-button-media-plan-details-${targetId}-modal`}
    />
  );
};

export default DefaultTargetModal;
