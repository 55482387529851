import React, { useEffect, useState } from 'react';
import Select, { components, InputActionMeta } from 'react-select';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './VrrDropdown.scss';
import { IListState, IVrrDropdown } from './types';

const VrrDropdown: React.FC<IVrrDropdown> = ({
  list,
  currentValue,
  placeholder,
  noOptionsMessage,
  handleChangeAction,
  handleSearchChangeAction,
  searchValue,
  isDisabled,
  id,
  isMulti,
  isClearable,
  isSearchable,
  isLoading,
  customComponents
}: IVrrDropdown) => {
  const [item, setItem] = useState<IListState | IListState[] | null>(null);
  const { t } = useTranslation();

  const displayItem = () => {
    const clonedList = [...list] as IListState[];
    if (Array.isArray(currentValue)) {
      const items: IListState[] = [];
      (currentValue as string[]).forEach((value) => {
        const foundItem = clonedList.find((i) => i.value === value);
        if (foundItem) {
          items.push(foundItem);
        }
      });
      setItem(items);
    } else {
      setItem(clonedList.find((i) => i.value === currentValue) ?? null);
    }
  };

  useEffect(() => {
    if (currentValue && list.length > 0) {
      displayItem();
    }
  }, [list, currentValue]);

  const handleChange = (newValue: any) => {
    if (handleChangeAction) {
      handleChangeAction(newValue);
      setItem(newValue);
    }
  };

  const handleSearchChange = (inputText: string, meta: InputActionMeta) => {
    if (handleSearchChangeAction) {
      handleSearchChangeAction(inputText, meta);
    }
  };

  const OptionMulti = (props: any) => {
    return (
      <components.Option {...props}>
        <div className="vrr-dropdown-multi-option">
          <Input
            id={props.value}
            type="checkbox"
            checked={props.isSelected}
            className="multi-option-checkbox"
          />
          <label htmlFor={props.value} className="check">
            <span className="cyan-checkbox" />
            {props.label}
          </label>
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      noOptionsMessage={() =>
        noOptionsMessage ?? t('components.dropdown.noOptionsMessage')
      }
      options={list || []}
      onChange={handleChange}
      onInputChange={handleSearchChange}
      inputValue={searchValue}
      value={item}
      placeholder={placeholder ?? t('components.dropdown.placeholder')}
      className="vrr-dropdown"
      classNamePrefix="vrr-dropdown"
      id={id}
      isDisabled={isDisabled}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={!isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isLoading={isLoading}
      isOptionDisabled={(option) => option?.disabled ?? false}
      {...(isMulti && {
        components: { Option: OptionMulti }
      })}
      components={customComponents}
    />
  );
};

export default VrrDropdown;
