import moment from 'moment';
import { TMediaPlanDetails } from '../../../media_plan/media_plan_details/types';
import { TBudgets } from './inputs/types';
import { TDates } from './dates/types';

const prepareMain = (mediaPlan: TMediaPlanDetails, budget: TBudgets) => {
  if (!!mediaPlan.targets?.length) {
    mediaPlan.targets.forEach((target) => {
      if (
        !!target.funnel.length &&
        ((budget && !Object.keys(budget).includes(target.funnel)) ?? !budget)
      ) {
        budget = {
          ...budget,
          [target.funnel]: 0
        };
      }
    });
  }
  return budget;
};

const prepareBottom = (mediaPlan: TMediaPlanDetails, budget: TBudgets) => {
  if (
    (mediaPlan.target_other && mediaPlan.target_other > 0) ??
    (mediaPlan.target_custom_intent && mediaPlan.target_custom_intent > 0)
  ) {
    return {
      ...budget,
      bottom: 0
    };
  }
  return budget;
};

const prepareUnspecified = (mediaPlan: TMediaPlanDetails, budget: TBudgets) => {
  if (
    (mediaPlan.target_your_data_app_users &&
      mediaPlan.target_your_data_app_users > 0) ??
    (mediaPlan.target_your_data_clients &&
      mediaPlan.target_your_data_clients > 0) ??
    (mediaPlan.target_your_data_yt_users &&
      mediaPlan.target_your_data_yt_users > 0)
  ) {
    return {
      ...budget,
      unspecified: 0
    };
  }

  return budget;
};

export const prepareBudget = (mediaPlan: TMediaPlanDetails) => {
  let newBudget: TBudgets = {};

  newBudget = prepareMain(mediaPlan, newBudget);
  newBudget = prepareBottom(mediaPlan, newBudget);
  newBudget = prepareUnspecified(mediaPlan, newBudget);

  return !!Object.keys(newBudget).length ? newBudget : undefined;
};

export const prepareBudgetValues = (budget: TBudgets) => {
  let newBudget: TBudgets = {};

  Object.keys(budget).forEach((key) => {
    const data = budget[key as keyof TBudgets];
    if (data) {
      newBudget = {
        ...newBudget,
        [key]: parseFloat(data.toString())
      };
    }
  });

  return newBudget;
};

export const prepareBudgetDates = (dates: TDates) => {
  let newDates: TDates = {};

  Object.keys(dates).forEach((key) => {
    const data = dates[key as keyof TDates];
    if (data) {
      newDates = {
        ...newDates,
        [key]: moment(data)
      };
    }
  });

  return newDates;
};
