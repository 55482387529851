type ColorsType = {
  base: string;
  dark: string;
  medium: string;
  light: string;
};

type ChartsColorsType = {
  name: string;
  colors: ColorsType;
};

const chartsColors: ChartsColorsType[] = [
  {
    name: 'red',
    colors: {
      base: '#ff4438',
      dark: 'rgba(255, 68, 56, 0.1)',
      medium: 'rgba(255, 68, 56, 0.05)',
      light: 'rgba(255, 68, 56, 0)'
    }
  },
  {
    name: 'turquoise',
    colors: {
      base: '#2cd3bf',
      dark: 'rgba(44, 211, 191, 0.1)',
      medium: 'rgba(44, 211, 191, 0.05)',
      light: 'rgba(44, 211, 191, 0)'
    }
  },
  {
    name: 'mauve',
    colors: {
      base: '#6c4cda',
      dark: 'rgba(108, 76, 218, 0.1)',
      medium: 'rgba(108, 76, 218, 0.05)',
      light: 'rgba(108, 76, 218, 0)'
    }
  },
  {
    name: 'yellow',
    colors: {
      base: '#ffc958',
      dark: 'rgba(255, 201, 88, 0.1)',
      medium: 'rgba(255, 201, 88, 0.05)',
      light: 'rgba(255, 201, 88, 0)'
    }
  }
];

const colorOfChart = (color?: string): ColorsType => {
  const foundColorMetric =
    chartsColors.find((element) => element.name === color) ?? chartsColors[0];

  return foundColorMetric.colors;
};

export const getColorByType = (
  context: CanvasRenderingContext2D,
  color?: string
): { baseColor: string; gradient: CanvasGradient } => {
  const colors = colorOfChart(color);
  const baseColor = colors.base;
  const gradient = context?.createLinearGradient(0, 350, 0, 50);
  gradient?.addColorStop(1, colors.dark);
  gradient?.addColorStop(0.7, colors.medium);
  gradient?.addColorStop(0.2, colors.light);

  return { baseColor, gradient };
};

export default colorOfChart;
