import { TCardInsight, TCardsContainer } from '../CardsContainer/types';
import {
  TCardInsightsPreference,
  TFilterPreference
} from '../FilterContainer/types';
import { INSIGHTS_CARD_STATUS } from '../../../services/constants/status';
import { TInsightsCards } from '../../../redux/userPreferences/types';
import { TTabID } from 'components/Insights/PublishTabs/types';

const changeHideStatus = (currentStatus: string): string => {
  switch (currentStatus) {
    case INSIGHTS_CARD_STATUS.HIDDEN:
      return INSIGHTS_CARD_STATUS.UNPUBLISHED;
    case INSIGHTS_CARD_STATUS.UNPUBLISHED:
    case INSIGHTS_CARD_STATUS.PUBLISHED:
    default:
      return INSIGHTS_CARD_STATUS.HIDDEN;
  }
};

const changePublishStatus = (currentStatus: string): string => {
  switch (currentStatus) {
    case INSIGHTS_CARD_STATUS.PUBLISHED:
      return INSIGHTS_CARD_STATUS.UNPUBLISHED;
    case INSIGHTS_CARD_STATUS.HIDDEN:
    case INSIGHTS_CARD_STATUS.UNPUBLISHED:
    default:
      return INSIGHTS_CARD_STATUS.PUBLISHED;
  }
};

const hasSameFiltersValues = (
  filterPreferences: TFilterPreference,
  card: TCardsContainer['card']
) => {
  return Object.keys(filterPreferences).every((filterKey: string) => {
    return (
      (filterPreferences as any)[filterKey] === (card as any)[filterKey] ||
      (filterPreferences as any)[filterKey] === 'all'
    );
  });
};

/**
 * Check if the current card exists in user settings or doesn't exists if the current status is unpublished
 * @param cardPreferences
 * @param card
 * @param status
 *
 * @return boolean
 */
const findSameCardInPreferences = (
  cardPreferences: any,
  card: any,
  status: any
): boolean => {
  return (
    cardPreferences.find(
      (preference: TCardInsightsPreference) =>
        card.card.id === preference.id && status === preference.status
    ) ||
    (status === INSIGHTS_CARD_STATUS.UNPUBLISHED &&
      !cardPreferences.find(
        (preference: TCardInsightsPreference) => card.card.id === preference.id
      ))
  );
};

const prepareByFilters = (
  cardList: TCardsContainer[],
  filterPreferences: TFilterPreference
) => {
  const cardsFiltered: TCardsContainer[] = [];
  if (Object.values(filterPreferences)?.length > 0) {
    cardList.forEach((cardInfos: TCardsContainer) => {
      const card: TCardsContainer['card'] = cardInfos.card;
      if (hasSameFiltersValues(filterPreferences, card)) {
        cardsFiltered.push(cardInfos);
      }
    });
    return cardsFiltered;
  }

  return cardList;
};

const prepareByPreferences = (
  cardList: TCardsContainer[],
  cardPreferences: TCardInsightsPreference[],
  status: TTabID
) => {
  let cardsFiltered: TCardsContainer[] = [];
  if (cardPreferences?.length > 0 && cardList?.length > 0) {
    cardList?.forEach((card: TCardsContainer) => {
      if (findSameCardInPreferences(cardPreferences, card, status)) {
        cardsFiltered.push(card);
      }
    });
  } else {
    cardsFiltered =
      status === INSIGHTS_CARD_STATUS.UNPUBLISHED && cardList.length > 0
        ? [...cardList]
        : [];
  }

  return cardsFiltered;
};

const prepareFilterInsights = (
  cardList: TCardsContainer[],
  filterPreferences: TFilterPreference,
  cardPreferences: TCardInsightsPreference[]
): TCardsContainer[] => {
  const filters: any = { ...filterPreferences };
  delete filters.status;
  delete filters.current_account_id;
  const cardsFiltered = prepareByFilters(cardList, filters);
  return prepareByPreferences(
    cardsFiltered,
    cardPreferences,
    filterPreferences.status
  );
};

const getCardPreferences = (
  cardId: string,
  cardPreferences: TCardInsightsPreference[]
): TCardInsightsPreference | null => {
  return cardPreferences.find((c) => c.id === cardId) ?? null;
};

const prepareInsights = (
  cardList: TCardInsight[],
  cardPreferences: TCardInsightsPreference[]
): TCardsContainer[] => {
  const list: TCardsContainer[] = [];
  cardList?.forEach((card) => {
    const cardSettings = getCardPreferences(card.id, cardPreferences);
    list.push(<TCardsContainer>{
      cardSettings,
      card: {
        ...card
      }
    });
  });
  return list;
};

const removeAllHideCards = (
  cardsPreferences: TInsightsCards
): TInsightsCards => {
  const newStatus = cardsPreferences.status.filter(
    (card: TCardInsightsPreference) =>
      card.status !== INSIGHTS_CARD_STATUS.HIDDEN
  );
  return {
    ...cardsPreferences,
    status: newStatus
  };
};

export default {
  prepareFilterInsights,
  prepareInsights,
  getCardPreferences,
  changeHideStatus,
  changePublishStatus,
  removeAllHideCards
};
