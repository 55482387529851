import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  getCurrentProvider,
  providers
} from '../../services/provider/getCurrentProvider';
import ChartDataTypes from '../../components/Charts/ChartDataTypes';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  registerables,
  ChartData
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { fetchCharts, getCharts } from '../../redux/overview/overviewActions';
import './ChartsContainer.scss';
import { IChartTypes } from 'components/Charts/ChartDataTypes/types';
import {
  getAccountId,
  getGMSettingsByCurrentAccount,
  getPeriod
} from '../../redux/userPreferences/actions/userPreferencesActions';
import { IChartContainer, TRangePeriod } from './types';
import { TDateRange } from '../../micro_front_end/nav_bar/date_time_picker/types';
import {
  ChartsContainerService,
  checkAccount,
  checkFunnel,
  checkPeriod,
  checkRange
} from './ChartsContainer.service';
import VrrDropdown from 'micro_front_end/shared_components/vrr_dropdown';
import { getCurrentLabel } from '../../redux/app/appActions';
import { TCurrencyOptions } from 'micro_front_end/services/formatters/types';
import { getCurrentCurrency } from '../../services/services';
import OverviewService from 'services/overview/overview.service';
import VrrCircleLoader from '../../micro_front_end/shared_components/vrr_circle_loader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ...registerables
);

export const ChartsC: React.FC<IChartContainer> = ({
  allCharts,
  currentAccountId,
  period,
  currentGlobalMetrics,
  fetchAllCharts,
  funnel
}: IChartContainer) => {
  const { t } = useTranslation();
  const localOptions: TCurrencyOptions = {
    thousandsSeparator: t('local.separators.thousands'),
    decimalSeparator: t('local.separators.decimal'),
    symbol: getCurrentCurrency()
  };

  const [charts, setCharts] = useState(allCharts);
  const [currentPeriod, setCurrentPeriod] = useState<TDateRange | null>(period);
  const [currentFunnel, setCurrentFunnel] = useState(funnel);
  const [accountId, setAccountId] =
    useState<IChartContainer['currentAccountId']>('');
  const [data, setData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: []
  });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>({
    animations: false,
    responsive: true,
    aspectRatio: 6,
    plugins: {
      legend: {
        display: false
      }
    }
  });
  const [chartRef, setChatRef] = useState(null);
  const [currentTypeData, setCurrentTypeData] =
    useState<IChartTypes['id']>('value');
  const [rangePeriod, setRangePeriod] = useState<TRangePeriod>({
    label: '',
    value: ''
  });
  const [previousRange, setPreviousRange] = useState<TRangePeriod>({
    label: '',
    value: ''
  });
  const [dropdownValues, setdropdownValues] = useState<TRangePeriod[]>([]);

  const [provider, setProvider] = useState<string>('all');
  useEffect(() => {
    const currentProvider = getCurrentProvider();
    if (providers.includes(currentProvider)) {
      setProvider(currentProvider);
    }
  });

  const ref = useCallback((node: any) => {
    setChatRef(node);
  }, []);

  useEffect(() => {
    if (period?.from && period?.to) {
      setRangePeriod(OverviewService.defaultValue(period));
      setdropdownValues(OverviewService.dropdownValues(period));
    }
  }, [period]);

  const checkAccountDiff = () => {
    return (
      checkPeriod(period, currentPeriod) ||
      checkAccount(currentAccountId, accountId) ||
      checkFunnel(funnel, currentFunnel) ||
      checkRange(previousRange.value, rangePeriod.value)
    );
  };

  useEffect(() => {
    if (
      period?.from &&
      period?.to &&
      currentAccountId &&
      provider !== '' &&
      checkAccountDiff()
    ) {
      fetchAllCharts(currentAccountId, period, rangePeriod.value, provider);
      setLoading(true);
    }
  }, [currentAccountId, funnel, provider, rangePeriod, period]);

  useEffect(() => {
    if (period && Object.values(allCharts).length > 0) {
      setCurrentPeriod(period);
      setCurrentFunnel(funnel);
      setAccountId(currentAccountId);
      setCharts(allCharts);
      setLoading(false);
    }
  }, [allCharts]);

  useEffect(() => {
    if (chartRef) {
      const chartData = ChartsContainerService.setDataService(
        charts,
        chartRef,
        currentTypeData,
        currentGlobalMetrics
      );
      const chartOptions = ChartsContainerService.setOptions(
        charts,
        currentTypeData,
        localOptions
      );

      setData(chartData);
      setOptions(chartOptions);
    }
  }, [chartRef, charts, currentTypeData, currentGlobalMetrics]);

  const handleChangeData = (dataId: IChartTypes['id']) => {
    setCurrentTypeData(dataId);
  };
  return (
    <Card className="card-chart">
      <VrrCircleLoader isEnable={loading}>
        <div className="chart-header">
          <h5>{t('components.chart.title')}</h5>
          <ChartDataTypes onClickAction={handleChangeData} />
          <div className="select-range-period">
            <VrrDropdown
              list={dropdownValues}
              handleChangeAction={(e: any) => {
                setPreviousRange(rangePeriod);
                setRangePeriod(
                  e ||
                    ({
                      value: 'weekly',
                      label: t('components.charts.weeks')
                    } as TRangePeriod)
                );
              }}
              currentValue={rangePeriod.value}
            />
          </div>
        </div>
      </VrrCircleLoader>
      <Line ref={ref} data={data} options={options} />
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  allCharts: getCharts(state),
  currentAccountId: getAccountId(state),
  period: getPeriod(state),
  currentGlobalMetrics: getGMSettingsByCurrentAccount(state),
  funnel: getCurrentLabel(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllCharts: (
    accountId: string,
    period: TDateRange,
    rangePeriod: string,
    p: string
  ) => {
    dispatch(
      fetchCharts({
        accountId,
        period,
        rangePeriod,
        p
      })
    );
  }
});

const ChartsContainer = connect(mapStateToProps, mapDispatchToProps)(ChartsC);

export default ChartsContainer;
