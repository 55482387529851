import { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { IVrr } from 'redux/vrrTypes';

const saveSession = async (
  response: AxiosResponse,
  rememberMe: string
): Promise<IVrr> => {
  const access_token = response.data.token;
  const cookies = new Cookies();

  if (access_token) {
    await cookies.set('access_token', access_token, {
      path: '/',
      sameSite: 'strict',
      maxAge: response.data.expires_in,
      secure: true
    });

    localStorage.setItem(
      'vrr-adtech',
      JSON.stringify({
        id: response.data.resource_owner.id,
        role: response.data.resource_owner.role || '',
        session: response.data.resource_owner.email,
        rememberMe: rememberMe
      })
    );

    return {
      id: response.data.resource_owner.id,
      session: response.data.resource_owner.email,
      error: { msg: null },
      role: response.data.resource_owner.role || false,
      rememberMe: rememberMe
    };
  }
  return {
    id: undefined,
    session: '',
    error: { msg: 'No found' },
    role: false,
    rememberMe: rememberMe
  };
};

const currentUserDataSession = (): {
  id?: number;
  session?: string;
  role?: string;
  rememberMe?: string;
} | null => {
  const rawUserData = localStorage.getItem('vrr-adtech');
  if (rawUserData) {
    const userdata = JSON.parse(rawUserData);
    return {
      id: userdata?.id,
      session: userdata?.session,
      role: userdata?.role || '',
      rememberMe: userdata?.rememberMe
    };
  }

  return null;
};

export { currentUserDataSession, saveSession };
