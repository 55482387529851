import React from 'react';
import VideoInformation from './video_information';
import VideoScoringSection from '../../../../creative_analytics/creative_video_details/video_scoring_section';
import { TVideoLine } from './types';
import './VideoLine.scss';

const VideoLine: React.FC<TVideoLine> = ({
  vrr_id,
  status,
  name,
  location,
  platform,
  objective,
  devices,
  score
}) => {
  return (
    <div className="video-line">
      <VideoInformation
        vrr_id={vrr_id}
        name={name}
        devices={devices}
        location={location}
        objective={objective}
        platform={platform}
        status={status}
      />
      <VideoScoringSection percent={score} />
    </div>
  );
};

export default VideoLine;
