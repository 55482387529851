import React, { useState, useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import ZoneDropdown from '../zone_dropdown';
import TargetList from '../../target_list';
import { prepareAddTag, prepareChangeTag, prepareRemoveTag } from './services';
import { TZoneBodyModal } from './types';
import { TMediaPlanDetails } from '../../../../../types';
import { TVrrTag } from '../../../../../../../shared_components/vrr_tag/types';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';
import './ZoneBodyModal.scss';

export const ZoneBodyModal: React.FC<TZoneBodyModal> = ({
  zones,
  onChangeAction
}) => {
  const [tags, setTags] = useState<TVrrTag[]>([]);
  const [currentValues, setCurrentValues] = useState<string[]>([]);

  const addTags = (zoneList: TMediaPlanDetails['zones']) => {
    const { newCurrentValues, newTags } = prepareAddTag(zoneList);
    setCurrentValues(newCurrentValues);
    setTags(newTags);
  };

  useEffect(() => {
    if (zones) {
      addTags(zones);
    }
  }, [zones]);

  const onChange = (zoneList: IListState[] | string[]) => {
    if (onChangeAction) {
      const newCurrentZones: TMediaPlanDetails['zones'] =
        prepareChangeTag(zoneList);

      onChangeAction(newCurrentZones);
    }
  };

  const removeTag = (id?: string) => {
    const { newCurrentValues, newTags } = prepareRemoveTag(
      currentValues,
      tags,
      id
    );
    setTags(newTags);
    setCurrentValues(newCurrentValues);
    onChange(newCurrentValues);
  };

  return (
    <ModalBody className="zone-body-modal">
      <ZoneDropdown onChange={onChange} selectedZones={currentValues} />
      <TargetList
        title="Zones"
        onClickAction={(id: string) => removeTag(id)}
        onRemoveAllAction={removeTag}
        tags={tags}
      />
    </ModalBody>
  );
};

export default ZoneBodyModal;
