import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from 'reactstrap';
import { VrrButton } from '../../../../../../../shared_components';
import { TOptimizationHeader } from './types';
import './OptimizationHeader.scss';

const OptimizationHeader: React.FC<TOptimizationHeader> = ({
  canAdd,
  onClickAction
}) => {
  const { t } = useTranslation();

  return (
    <ModalHeader className="vrr-modal-header">
      <span>
        <i className="tim-icons icon-spaceship circle-icon" />
      </span>
      <span>
        {t('media_plan_edit.media_plan_config.optimization.modal_header.title')}
      </span>
      <VrrButton
        className="btn-round optimization-modal-header-add"
        disabled={!canAdd}
        onClick={onClickAction}
        icon={{
          iconLabel: 'tim-icons icon-simple-add'
        }}
      >
        {t(
          'media_plan_edit.media_plan_config.optimization.modal_header.add_button'
        )}
      </VrrButton>
    </ModalHeader>
  );
};

export default OptimizationHeader;
