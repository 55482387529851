import React from 'react';
import { PrivateSection } from './PrivateSection';
import { atLeastOneStrategy } from './services';
import { TPrivateSectionProps } from './types';

/**
 * @param requires
 * @param authorizationStrategy
 * @param children
 * @param useCallback if you need customize render component (more than only hide/show)
 * @constructor
 */
const PrivateComponent: React.FC<TPrivateSectionProps> = ({
  requires,
  authorizationStrategy,
  children,
  useCallback = false
}: TPrivateSectionProps) => {
  return (
    <PrivateSection
      requires={requires}
      useCallback={useCallback}
      authorizationStrategy={authorizationStrategy ?? atLeastOneStrategy}
    >
      {({ isAuthorized }: any) => {
        if ((requires || authorizationStrategy) && !useCallback) {
          return isAuthorized ? children : null;
        }

        if (useCallback) {
          return children({ isAuthorized });
        }

        return children;
      }}
    </PrivateSection>
  );
};

export default PrivateComponent;
