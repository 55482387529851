import { MutableRefObject } from 'react';
import type { ChartData } from 'chart.js';
import { getColorByType } from '../../components/Charts/chartsColors';
import {
  IMPRESSIONS,
  RATIO,
  SPENT,
  VALUE
} from '../../components/Charts/ChartDataTypes/ChartDataTypes';
import { TChart, TChartData, TCharts } from './types';
import { TGlobalMetric } from '../../redux/userPreferences/types';
import { TDateRange } from '../../micro_front_end/nav_bar/date_time_picker/types';
import formatDataService from '../../micro_front_end/services/formatters/formatData';
import { TCurrencyOptions } from 'micro_front_end/services/formatters/types';
import './ChartsContainer.scss';

export const checkPeriod = (
  period: TDateRange | null,
  currentPeriod: TDateRange | null
): boolean => {
  return (
    period?.from !== currentPeriod?.from || period?.to !== currentPeriod?.to
  );
};

export const checkAccount = (
  currentAccountId: string | null,
  accountId: string | null
): boolean => {
  return currentAccountId !== accountId;
};

export const checkFunnel = (
  newFunnel: string | null,
  currentFunnel: string
): boolean => {
  return newFunnel !== currentFunnel;
};

export const checkRange = (
  previousRange: string,
  rangePeriod: string
): boolean => {
  return previousRange !== rangePeriod;
};

const getChartLabel = (
  chart: TChart,
  currentTypeData: keyof TChart
): string | number[] | TChartData[] => {
  return chart.type === SPENT ||
    currentTypeData === VALUE ||
    (chart.type === IMPRESSIONS && currentTypeData === RATIO)
    ? chart.name
    : chart[`${currentTypeData}Name` as keyof TChart] ?? '';
};

const formatChartData = (
  data: {
    charts: Array<TChart>;
    labels: Array<string>;
  },
  localOptions: TCurrencyOptions
): any => {
  const { thousandsSeparator, decimalSeparator, symbol } = localOptions;
  const currency = symbol ? symbol : '';
  return {
    ...data,
    charts: {
      ...data.charts.map((dataChartIterate: any) => {
        return {
          ...dataChartIterate,
          value: dataChartIterate.value.map((v: any) =>
            formatDataService.dataFormatter(
              v,
              currency,
              thousandsSeparator,
              decimalSeparator
            )
          ),
          cost: dataChartIterate.cost
            ? dataChartIterate.cost.map((v: any) =>
                formatDataService.dataFormatter(
                  v,
                  currency,
                  thousandsSeparator,
                  decimalSeparator
                )
              )
            : [],
          ratio: dataChartIterate.ratio
            ? dataChartIterate.ratio.map((v: any) =>
                formatDataService.dataFormatter(
                  v,
                  currency,
                  thousandsSeparator,
                  decimalSeparator
                )
              )
            : []
        };
      })
    }
  };
};

const getChartData = (
  chart: TChart,
  currentTypeData: keyof TChart
): Array<number | string> => {
  const result: Array<number | string> = chart[currentTypeData]
    ? (chart[currentTypeData] as TChartData[])?.map((v) => v.value)
    : [];
  return chart.type === SPENT ||
    currentTypeData === VALUE ||
    (chart.type === IMPRESSIONS && currentTypeData === RATIO)
    ? chart.value.map((v: any) => v.value)
    : result;
};

const getScales = (
  charts: TCharts
): {
  [key: string]: {
    display: number;
  };
} => {
  let scales = {};
  Object.values(charts.charts).forEach((chart: TChart) => {
    scales = {
      ...scales,
      [`y${chart.name}`]: {
        display: 0
      }
    };
  });
  return scales;
};

const getTooltipLabel = (
  tooltipItem: {
    dataset: {
      label: string;
      data: { [key: string]: number };
    };
    dataIndex: string;
  },
  charts: any,
  currentTypeData: keyof TChart
): string => {
  const { label } = tooltipItem.dataset;
  let currentDataNumber: any = null;
  const chartsKey: Array<string> = Object.keys(charts);
  const dataIndex =
    currentTypeData === 'value' ? 'name' : `${currentTypeData}Name`;
  chartsKey.map((chartKey: any) => {
    if (label === 'Spent') {
      currentDataNumber = charts[0].value[tooltipItem.dataIndex];
    }
    if (label === 'Impressions' && currentTypeData === 'ratio') {
      currentDataNumber = charts[3].value[tooltipItem.dataIndex];
    }
    if (charts[chartKey][dataIndex] === tooltipItem.dataset.label) {
      currentDataNumber =
        charts[chartKey][currentTypeData][tooltipItem.dataIndex];
    }
  });
  return `${label}: ${currentDataNumber}`;
};

const setDataService = (
  charts: TCharts,
  canvasRef: MutableRefObject<any>['current'],
  currentTypeData: keyof TChart,
  currentGlobalMetrics?: TGlobalMetric | null
): ChartData<'line'> => {
  if (Object.values(charts).length > 0) {
    return {
      labels: charts.labels,
      datasets: [
        ...Object.values(charts.charts).map((chart: TChart) => {
          const metric = currentGlobalMetrics?.metrics?.find(
            (m) => m.name.value === chart.name && m.color && m.color !== 'grey'
          );
          const { baseColor, gradient } = getColorByType(
            canvasRef?.ctx,
            metric?.color
          );

          return {
            label: getChartLabel(chart, currentTypeData) as string,
            pointBackgroundColor: baseColor,
            fill: true,
            borderColor: baseColor,
            backgroundColor: gradient,
            data: [...(getChartData(chart, currentTypeData) as any)],
            tension: 0.2,
            yAxisID: `y${chart.name}`,
            hidden: chart.type !== SPENT && !metric
          };
        })
      ]
    };
  }

  return {
    datasets: [],
    labels: []
  };
};

const setOptions = (
  charts: {
    charts: Array<TChart>;
    labels: Array<string>;
  },
  currentTypeData: keyof TChart,
  localOptions: TCurrencyOptions
): {
  animations: boolean;
  responsive: boolean;
  aspectRatio: number;
  interaction: {
    mode: string;
    intersect: boolean;
  };
  scales: {
    [key: string]: {
      display: number;
    };
  };
  plugins: {
    legend: {
      display: boolean;
    };
    tooltip: {
      enabled: boolean;
      callbacks: {
        label: (tooltipItem: {
          dataset: {
            label: string;
            data: {
              [key: string]: number;
            };
          };
          dataIndex: string;
        }) => string;
      };
    };
  };
} | null => {
  if (Object.values(charts).length > 0) {
    const newChart = formatChartData(charts, localOptions);
    return {
      animations: false,
      responsive: true,
      aspectRatio: 6,
      interaction: {
        mode: 'index' as const,
        intersect: false
      },
      scales: {
        ...getScales(newChart)
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (tooltipItem) =>
              getTooltipLabel(tooltipItem, newChart.charts, currentTypeData)
          }
        }
      }
    };
  }
  return null;
};
export const ChartsContainerService = {
  setOptions,
  getScales,
  setDataService,
  formatChartData
};
