import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeckMetrics from './deck_metrics';
import { addColor, removeColor, setSelectedMetricsInAll } from './services';
import {
  getCurrentProvider,
  providers
} from '../../../services/provider/getCurrentProvider';
import useSettings from '../../../libs/settings/useSettings';
import {
  getAccountId,
  getPeriod,
  getGMSettingsByCurrentAccount
} from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  globalMetricsAll,
  fetchMetrics
} from '../../../redux/overview/overviewActions';
import { getCurrentLabel } from '../../../redux/app/appActions';
import { TMetric } from '../../../redux/userPreferences/types';
import { TMainMetricDataUnit } from '../metrics/metrics/metric_table/types';
import { TSelectionMetric } from './deck_metrics/card_metric/types';

const GlobalMetrics: React.FC = () => {
  const dispatch: any = useDispatch();
  const allMetrics = useSelector(globalMetricsAll);
  const selectedMetrics = useSelector(getGMSettingsByCurrentAccount);
  const datePickerPeriod = useSelector(getPeriod);
  const accountId = useSelector(getAccountId);
  const funnel = useSelector(getCurrentLabel);
  const [account, setAccount] = useState(accountId);
  const [period, setPeriod] = useState(datePickerPeriod);
  const [currentFunnel, setCurrentFunnel] = useState(funnel);
  const [metrics, setMetrics] = useState<TMetric[]>([]);
  const [metricColors, setMetricColors] = useState([
    'turquoise',
    'mauve',
    'yellow'
  ]);
  const [provider, setProvider] = useState<string>('all');
  const { overview } = useSettings();

  useEffect(() => {
    const currentProvider = getCurrentProvider();
    if (providers.includes(currentProvider)) {
      setProvider(currentProvider);
    }
  });

  useEffect(() => {
    if (
      accountId !== account ||
      period?.from !== datePickerPeriod?.from ||
      period?.to !== datePickerPeriod?.to ||
      funnel !== currentFunnel
    ) {
      setAccount(accountId);
      setPeriod(datePickerPeriod);
      setCurrentFunnel(funnel);
    }
  }, [datePickerPeriod, accountId, funnel]);

  useEffect(() => {
    if (
      account &&
      period?.from &&
      period?.to &&
      currentFunnel &&
      provider !== ''
    ) {
      dispatch(fetchMetrics({ accountId: account, period, p: provider }));
    }
  }, [period, account, currentFunnel, provider]);

  useEffect(() => {
    if (
      selectedMetrics?.metrics &&
      selectedMetrics?.metrics?.length > 0 &&
      metrics.length === 0
    ) {
      setMetrics(selectedMetrics?.metrics);
    }
  }, [selectedMetrics]);

  useEffect(() => {
    if (metrics.length > 0) {
      const newMetricColors = [...metricColors];
      metrics.forEach((metric) => {
        if (metric.color) {
          const index = newMetricColors.indexOf(metric.color);
          if (index > -1 && newMetricColors.length > 0) {
            newMetricColors.splice(index, 1);
          }
        }
      });
      setMetricColors(newMetricColors);
    }
  }, [metrics]);

  const updatePreferences = (
    newSetting: {
      name: TMainMetricDataUnit;
      color?: string;
    }[]
  ): void => {
    if (newSetting) {
      overview('global_metrics', { metrics: newSetting });
    }
  };

  const onUpdate = (dataStore: TSelectionMetric[]): void => {
    const newSetting = dataStore
      .filter((metric: TSelectionMetric) => !!metric.selected)
      .map((metric: TSelectionMetric) => {
        return { name: metric.name, color: metric?.color };
      });

    setMetricColors(['turquoise', 'mauve', 'yellow']);
    setMetrics(newSetting);
    updatePreferences(newSetting);
  };

  const changeColor = (name: string, color: string) => {
    const all = setSelectedMetricsInAll(allMetrics, selectedMetrics);
    const newDataStore: TSelectionMetric[] | undefined = all?.map((metric) => {
      const newMetric = { ...metric };
      if (newMetric.name.value === name) {
        if (color === 'grey' && metricColors.length > 0) {
          const newColor = metricColors[0];
          removeColor(newColor, metricColors, setMetricColors);
          return {
            ...newMetric,
            color: newColor
          };
        }
        if (newMetric?.color) {
          delete newMetric?.color;
          addColor(color, metricColors, setMetricColors);
        }
      }
      return newMetric;
    });
    const newSetting = newDataStore
      ?.filter((metric: TSelectionMetric) => !!metric.selected)
      .map((metric: TSelectionMetric) => {
        return { name: metric.name, color: metric?.color };
      });
    if (newSetting) updatePreferences(newSetting);
  };

  return (
    <DeckMetrics
      metrics={setSelectedMetricsInAll(allMetrics, selectedMetrics)}
      onSubmitAction={onUpdate}
      onClickAction={changeColor}
    />
  );
};

export default GlobalMetrics;
