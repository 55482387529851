export type TMediaPlanAssetsCard = {
  type: TAssetsCardType;
  assets: Array<TAssetRedirection | TAssetCTA>;
  onClickEditButton?: (currentTarget: string) => void;
};

export type TAssetsCardType = 'redirections' | 'call_to_actions';

export type TAssetRedirection = {
  id: number | null;
  display_url?: string;
  final_url: string;
};

export type TAssetCTA = {
  id: number | null;
  title: string;
  button: string;
};

export const FOOTER_COLOR = {
  redirections: 'orange',
  call_to_actions: 'orange'
};
