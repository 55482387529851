import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VrrButtonGroup,
  VrrDropdown,
  VrrInput
} from '../../../../../shared_components';
import {
  DEVICE,
  OBJECTIVE,
  PLATFORM,
  ROOT_LOCATION,
  LANGUAGE,
  prepareDevice,
  prepareUpdateDevice
} from './services';
import { TDetailPreview } from './types';
import { IListState } from '../../../../../shared_components/vrr_dropdown/types';
import { TPreview } from '../../../../../../redux/creativeAnalytics/creativeImportVideo/types';
import './DetailPreview.scss';

const DetailPreview: React.FC<TDetailPreview> = ({
  details,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<TPreview['details'] | undefined>(
    undefined
  );
  const [device, setDevice] = useState<string>(DEVICE[0].id);

  useEffect(() => {
    if (details) {
      setPreview(details);
    }

    if (details?.devices) {
      const d = prepareDevice(details?.devices);
      setDevice(d);
    }
  }, [details]);

  const onChange = (
    key: keyof TPreview['details'],
    value: string | TPreview['details']['devices']
  ) => {
    const newPreview: TPreview['details'] = {
      ...preview,
      [key]: value
    };
    setPreview(newPreview);
    if (onChangeAction && preview) onChangeAction(newPreview);
  };

  return (
    <>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.detail.title.objective')}
        </span>
        <hr />
      </div>
      <div className="detail-preview">
        <VrrButtonGroup
          defaultId={details?.objective ?? OBJECTIVE[0].id}
          options={OBJECTIVE}
          onClickAction={(id) => onChange('objective', id)}
        />
      </div>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.detail.title.device')}
        </span>
        <hr />
      </div>
      <div className="detail-preview-device">
        <VrrButtonGroup
          defaultId={device}
          options={DEVICE}
          onClickAction={(id) => onChange('devices', prepareUpdateDevice(id))}
        />
      </div>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.detail.title.platform')}
        </span>
        <hr />
      </div>
      <div className="detail-preview">
        <VrrDropdown
          list={PLATFORM}
          currentValue={details?.platform}
          handleChangeAction={(e: IListState) => onChange('platform', e.value)}
        />
      </div>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.detail.title.language')}
        </span>
        <hr />
      </div>
      <div className="detail-preview">
        <VrrDropdown
          list={LANGUAGE}
          currentValue={details?.language}
          handleChangeAction={(e: IListState) => onChange('language', e.value)}
        />
      </div>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.detail.title.location')}
        </span>
        <hr />
      </div>
      <VrrInput isDisabled value={ROOT_LOCATION} />
    </>
  );
};

export default DetailPreview;
