import React from 'react';
import { PrivateComponent } from '../../../../../permissions';
import { VrrButton } from '../../../../../shared_components';
import { ROLES } from '../../../../../services/vrrRoles';
import { TEditButton } from './types';
import './EditButton.scss';

const EditButton: React.FC<TEditButton> = ({
  onClickEditButton,
  titleId,
  label,
  iconLabel
}: TEditButton) => {
  const classToApply = label
    ? 'btn-with-icon-and-label btn-space-around icon-left btn-icon-small-xs'
    : 'icon-btn';

  return (
    <PrivateComponent requires={[ROLES.MEDIA_BUYER]}>
      <VrrButton
        dataCy={`${titleId}-edit-button`}
        className={`btn-round ${classToApply} media-plan-details-edit-button`}
        onClick={() => {
          if (onClickEditButton) onClickEditButton(titleId);
        }}
        icon={{
          iconLabel: iconLabel
        }}
      >
        {label ? <span className="label">{label}</span> : <></>}
      </VrrButton>
    </PrivateComponent>
  );
};

export default EditButton;
