import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ImportInputUrl from './import_input_url';
import { getAccountId } from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import { getVideoErrors } from '../../../../../redux/creativeAnalytics/creativeImportVideo/creativeImportVideoActions';
import { TImportForm } from './types';
import { TUrlError } from '../../../../../redux/creativeAnalytics/creativeImportVideo/types';

const ImportForm: React.FC<TImportForm> = ({
  urls,
  onChangeAction,
  onRemoveAction
}) => {
  const { t } = useTranslation();
  const currentAccountId = useSelector(getAccountId);
  const errors = useSelector(getVideoErrors) as TUrlError[];

  const onChange = (id: string, e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (onChangeAction) onChangeAction(id, value);
  };

  const getErrors = (url: string) => {
    let err: string | undefined;
    errors?.forEach((error) => {
      if (error.url === url) {
        err = t(
          'creative_gallery.creative_import.import_video.import_modal.url_not_found'
        );
      }
    });
    return err;
  };

  const onRemove = (id: string) => {
    if (onRemoveAction) onRemoveAction(id);
  };

  return (
    <Form className="import-form">
      <span>
        {t('creative_gallery.creative_import.import_video.form.label.account')}
      </span>
      <p>{currentAccountId}</p>
      <FormGroup className="creative-modal-form-input">
        <Label>
          {t(
            'creative_gallery.creative_import.import_video.form.label.videoLink'
          )}
        </Label>
        {urls?.map((url: any) => {
          return (
            <ImportInputUrl
              key={url.id}
              url={url.linkValue}
              placeholder={t(
                'creative_gallery.creative_import.import_video.form.urlPlaceholder'
              )}
              {...(getErrors(url.linkValue) && {
                errorLink: getErrors(url.linkValue)
              })}
              canRemoveLine={urls?.length > 1}
              onRemoveLine={() => onRemove(url.id)}
              onChangeInputUrl={(e) => onChange(url.id, e)}
            />
          );
        })}
      </FormGroup>
    </Form>
  );
};

export default ImportForm;
