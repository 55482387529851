import { TMediaPlanDetails } from '../../../../types';

export const sortPixelsList = (
  pixelsList: TMediaPlanDetails['pixels']
): TMediaPlanDetails['pixels'] => {
  if (pixelsList.length == 1 || pixelsList.length == 0) {
    return pixelsList;
  }
  const sortedArray = [...pixelsList];
  sortedArray.sort((p1, p2) => {
    const last = p1.rank < p2.rank ? -1 : 0;
    return p1.rank > p2.rank ? 1 : last;
  });
  return sortedArray;
};

export const checkValidation = (
  currentValues: TMediaPlanDetails['pixels']
): boolean => {
  return (
    (currentValues.length > 1 &&
      !currentValues.some((value) => value.id === -1)) ||
    currentValues.length === 1
  );
};
