import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SectionTitle from '../section_title';
import VrrVideoPlayer from '../../../shared_components/vrr_video_player';
import { getUserId } from '../../../../redux/app/appActions';
import { prepareSource } from './services';
import { v4 as uuid } from 'uuid';
import { TPlayerSection } from './types';

const PlayerSection: React.FC<TPlayerSection> = ({
  videoId,
  accountId,
  token,
  chapters,
  saliencyData
}) => {
  const { t } = useTranslation();
  const userId = useSelector(getUserId);
  const [source, setSource] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      !!accountId?.length &&
      !!videoId?.length &&
      !!token?.length &&
      !!userId
    ) {
      const src = prepareSource(accountId, videoId, token, userId.toString());
      setSource(src);
    }
  }, [accountId, videoId, userId, token]);

  useEffect(() => {
    return () => {
      setSource(undefined);
    };
  }, []);

  return (
    <>
      <SectionTitle
        title={t('creative_video_details.section_title.player_section')}
        iconClassName="tim-icons icon-atom"
      />
      {!!source?.length && (
        <VrrVideoPlayer
          id={`player-section-${uuid()}`}
          width="960"
          height="540"
          source={[
            {
              type: 'video/webm',
              src: source
            }
          ]}
          chapters={chapters}
          saliencyData={saliencyData}
        />
      )}
    </>
  );
};

export default PlayerSection;
