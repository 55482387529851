import { TImportUrl } from '../../../../../redux/creativeAnalytics/creativeImportVideo/types';
import { v4 as uuid } from 'uuid';

export const prepareUrls = (
  id: string,
  value: string,
  urls?: TImportUrl[]
): TImportUrl[] => {
  if (!!urls?.length) {
    const newUrls = [...urls];
    return newUrls.map((link) => {
      if (link.id === id) {
        return {
          ...link,
          linkValue: value
        };
      }
      return link;
    });
  }
  return [{ linkValue: '', id: uuid() }];
};

export const prepareRemoveUrls = (
  id: string,
  urls?: TImportUrl[]
): TImportUrl[] => {
  if (!!urls?.length) {
    const newUrls = [...urls];
    return newUrls.filter((value) => value.id !== id);
  }
  return [{ linkValue: '', id: uuid() }];
};
